@import 'src/scss/helpers/mixins';

.dropdown-menu-button-item {
  display: block;
  width: 100%;
  height: 100%;
  @include max-screen(600) {
    min-height: 48px;
  }
  * {
    display: block;
    width: 100%;
    height: 100%;
    @include max-screen(600) {
      min-height: 48px;
    }
  }
}