@import '/src/variables.scss';

.policies-modal {
  border-radius: $modalBorderRadius;

  &.modal-footer {
    border-top: 0 none;

    @include max-screen(576) {
      padding: 1.5rem;

      .policies-button {
        height: 3rem;
      }
    }
  }

  &.modal-header {
    border-bottom: 0 none;
  }
}