@import 'src/variables.scss';
@import 'src/scss/helpers/mixins';

.documents-sub-header {
  padding: 1.75rem 0;
  background-color: $almostWhite;
  border-bottom: 1px solid $lightGray;

  &_title {
    @include text-formatting(1.25rem, $semiBoldText, $mainFont, $blackColor);
    margin-bottom: 1rem;
  }

  &_button {
    @include text-formatting(1rem, $normalText, $mainFont, $white);
    min-width: 224px;
    padding: 1rem 1.75rem;
    &--secondary {
      background-color: transparent;
      border: 1px solid $brandPrimary;
      color: $blackPrimary;
      &:hover {
        background-color: $white;
        color: $brandPrimary;
        border-color: $brandPrimary;
      }
    }
  }

  &_item-wrapper {
    padding: 0 0.25rem;

    .button-main.btn {
      height: 3rem;
    }
  }
}