@import 'src/variables.scss';

.editor-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid $lightGray;
  box-shadow: 0 4px 5px rgba(233, 239, 247, 0.36);
  min-height: $headerHeight;

  &-error {
    font-family: $mainFont;
    text-align: center;
    color: $inputError;
    font-size: $smallFontSize;
  }

  &-endblock {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .read-only-download {
    max-width: 128px;
  }

  &-title {
    font-family: $mainFont;
    text-align: center;
    font-size: $buttonFontSize;
    font-weight: $mediumText;
    color: $blackPrimary;
    padding: 0;
    border-radius: 5px;
    outline: none;
    width: 100%;
    margin: 0 auto;
    transition: all .4s ease;
    border: 1px solid transparent;

    &:not([readonly]):hover {
      border: 1px solid $mediumGray;
    }

    &:focus {
      border: 1px solid $brandPrimaryFocused;
      box-shadow: none;
      &:focus:hover {
        border: 1px solid $brandPrimaryFocused;
      }
    }

    &[readonly]:focus {
      border: 1px solid transparent;
    }

    &::placeholder {
      color: $mediumGray;
    }
  }

  &-dropdown {
    &:hover {
      background-color: $veryLightGray;
    }
  }

  &-dropdown-menu {
    transition: all 0.1s ease-out;
  }

  &-dropdown-item {
    &:hover {
      background-color: $veryLightGray;
    }
  }

  &-download-csv {
    color: unset;
    text-decoration: none;
    &:hover {
      color: unset;
    }
  }
}

.description-title {
  &-info {
    font-family: $mainFont;
    font-size: $mainFontSize;
    font-weight: $normalText;
    text-align: center;
  }
}