@import 'src/variables';
@import 'scss/helpers/mixins';

.set-signer {
  width: 100%;
  max-width: var(--sidebar-width);
  background: $white;
  &-menu-item {
    display: flex;
    align-items: center;
    gap: 5px;
    overflow: hidden;
  }
  &-icon {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    margin-right: 5px;
    flex-shrink: 0;
  }
  &-signer {
    display: flex;
    gap: 5px;
  }
}

.set-signers-header {
  display: grid;
  grid-template-columns: var(--sidebar-width) 1fr var(--sidebar-width);
  border-bottom: 1px solid $lightGray;
  box-shadow: 0 4px 5px rgba(233, 239, 247, 0.36);
  @include max-screen(992) {
    display: flex;
    flex-direction: column-reverse;
  }
}