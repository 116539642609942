@import 'src/scss/helpers/mixins';
@import 'src/variables';

.PublicFooter {
  padding: 0 0 5px;
  position: fixed;
  height: var(--footer-height);
  width: 100%;
  bottom: 0;
  left: 0;
  background: $white;
  box-shadow: 0px -7px 19px rgba($darkGray, .13);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 500;
}

.FillOutFooter {
  position: sticky;
}

.PublicButton {
  width: auto;
  @include max-screen(576) {
    width: 100%;
  }
}

.PublicFooterChildren {
  padding: 16px 0 3px;
}