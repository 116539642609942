@import 'src/variables';

.ModalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 30px);
  max-width: 768px;
  background-color: $white;
  padding: 15px 14px 33px;
  border-radius: 14px;
  box-shadow: 0 4px 17px rgba(83, 83, 83, 0.12);
}

.Title {
  font-family: $mainFont;
  font-weight: $mediumText;
  font-size: 18px;
  line-height: 1.6em;
  text-align: center;
  color: $blackColor;
  margin-bottom: 14px;
}

.List {
  font-family: $mainFont;
  font-weight: $normalText;
  font-size: $mainFontSize;
  line-height: 1.45em;
  text-align: center;
  color: $blackColor;
  margin-bottom: 16px;
}