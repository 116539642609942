@import '/src/variables.scss';

.subtypes-modal {
  max-width: 400px;
  margin: 0 auto;
  &-attention {
    color: $inputError;
    font-size: 13px;
    text-align: center;
    margin: 0;
  }
}