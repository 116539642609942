@import 'src/variables.scss';
@import 'src/scss/helpers/mixins';

.global-navigation {
  background-color: $almostWhite;
  border-bottom: 1px solid $lightGray;

  &_text {
    @include text-formatting(1.25rem, $semiBoldText, $mainFont, $blackColor);
  }

  &-item {
    background-color: $white;
    box-shadow: 0 4px 23px rgba(221, 232, 248, 0.16);
    border-radius: 1rem;
    border: 1px solid $lightGray;
    text-decoration: none;
    width: $globalNavigationSquareSize;
    height: $globalNavigationSquareSize;

    &:hover {
      color: inherit;
    }

    &_title {
      @include text-formatting(18px, $semiBoldText, $mainFont, $blackColor);
    }

    &_link {
      @include text-formatting(15px, $semiBoldText, $mainFont, $blackColor);

      text-decoration: none;
      border: 1px solid $brandPrimary;
      border-radius: 0.5rem;
      text-align: center;
      width: 100%;
      max-width: 67%;
      padding: .6rem .2rem;
      transition: all .5s ease;
      &:hover {
        background-color: transparent;
        border-color: transparent;
        color: $brandPrimary;
      }
      &--accent {
        background-color: $brandPrimary;
        color: $white;
        &:hover {
          background-color: $white;
          color: $brandPrimary;
          border-color: $brandPrimary;
        }
      }
    }
  }

  &__buttons {
    width:  100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  padding: 2.25rem 0 calc(20px + 1%);

  @media (max-width: 767px) {
    padding: 2.25rem 15px calc(40px + 1%);
  }

  @media (max-width: 400px) {
    padding: 1rem 15px calc(46px + 1%);
  }
}