@import 'src/variables.scss';

@import 'style/autosuggest.scss';
@import 'style/document.scss';
@import 'style/typography.scss';
@import 'style/syncLoader.scss';
@import 'style/responsiveStyles.scss';
@import 'style/editable.scss';
@import 'style/toolbar.scss';
@import 'style/forms.scss';
@import 'style/titleAndDescription.scss';
@import 'utils.scss';

@import "scss/general.scss";

body,
html {
  width: 100%;
  min-height: 100%;
  font-family: $mainFont;
  font-size: $defaultFontSize;
  font-weight: $normalText;
  color: $blackPrimary;
  margin: 0;
  padding: 0;
}

h1 {
  text-align: center;
}

.text-pink {
  color: $brandPrimary;
}

#accountIcon {
  height: 32px;
  width: 32px;
  background-color: $darkGray;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.material-icons {
  color: $brandSecondary;
  width: 18px;
  height: 18px;
}

.top-nav-menu-icon-responsive {
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
  }
}

.editor-size {
  height: $editorMainHeight;
  overflow-x: auto;

  @include max-screen(1399) {
    height: $editorMainHeightMobile;
  }
}

.public-editor-size {
  overflow: auto;
  height: calc(var(--height-only-public-editor) - 20px);

  @media (max-width: 400px) {
    height: calc(100vh - (46px + 7rem));
  }
}

.form-type-editor {
  max-width: 700px;
  margin: 0 auto;
}

.multi-template-page {
  .editor-size {
    height: calc(100vh - (20rem + 20px));
  }

  .public-editor-size {
    height: var(--height-only-public-editor);

    @media (max-width: 400px) {
      height: calc(100vh - (46px + 9rem));
    }
  }

  &.swiper-slide {
    padding: 0;
  }
}

.material-icons-add {
  width: 20px !important;
  height: 20px !important;
  margin-right: 5px;
}

.searchMatch {
  padding: 0;
  background: $green;
}

.footer {
  padding: 0.5%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: $white;
  box-shadow: 0 -5px 10px 0px rgba($darkGray, .13);
  justify-content: flex-start;
  z-index: 500;
  min-height: 50px;

  &__buttons {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .custom-select {
    max-width: calc(min(max(50%, 10px), 180px));
  }
}

.modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: block;

  &.complete-document-modal-body {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 3rem;
  }
}

.nav-item {
  cursor: pointer;
}

.border-without-top {
  height: calc(100vh - (191px + 6.5rem));
  overflow: auto;
  padding: 2rem 0 3rem;
  border-left: 1px solid $veryLightGray;
  border-right: 1px solid $veryLightGray;
  border-bottom: 1px solid $veryLightGray;
}

.form-fields-aside {
  width: 100%;
  min-width: 300px;
  max-width: 320px;
  position: relative;

  &__collapse {
    width: 40px;
    min-width: unset;
    overflow-x: hidden;
  }
}

.form-fields-tab-collapse {
  width: 160px;
  height: 160px;
  transform: rotate(-90deg) translateX(34px);
}

.form-fields-cancel-icon {
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  border-left: 1px solid $veryLightGray;
  border-right: 1px solid $veryLightGray;
}

.cursor {
  cursor: pointer;
}

.cancel-icon {
  z-index: 10;
  position: absolute;
  right: 6px;
  top: 47px;
}

.search {
  padding: 10px;
}

.tabs-collapse {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  transform: rotate(-90deg) translateX(-160px);
}

.modal-dialog {
  max-width: 900px;

  &.document-history-modal {
    max-width: 1140px;
  }

  &.complete-document-modal {
    max-width: 60%;

    @media (max-width: 1200px) {
      max-width: 100%;
    }
  }

  &.batch-send-CSV-modal {
    max-width: 95%;
  }

  &.app-payment-method {
    max-width: 450px;
  }
}

.menu-link,
.menu-link:hover {
  display: block;
  text-decoration: none;
  color: black;
  width: 100%;
  padding: 6px 16px;
  margin: -6px 0;
}

.kebab-menu-icon {
  display: inline-block;
  opacity: 0;

  & :hover {
    cursor: pointer;
  }
}

.MuiTableRow-root:hover .kebab-menu-icon {
  opacity: 1;
}

.kebab-menu-item {
  & a {
    color: $blackPrimary;
  }

  & .material-icons {
    color: rgba(0, 0, 0, 0.54);
    margin: 0 5px 4px 0;
    padding: 1px 0;
  }
}

.bg-loader {
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 10%;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  background: $veryLightGray;
}

.hidden {
  display: none;
}

.btn {
  width: $btnWidth;
}

.btn-big {
  width: auto;
}

.delete-icon {
  z-index: 10;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.settings-icon {
  z-index: 10;
  position: absolute;
  top: 5px;
  right: 33px;
  cursor: pointer;
}

.light-section-outline-icon {
  top: 12px;
}

.invalid-field {
  color: $inputError;
}

.w-fit-content {
  width: fit-content !important;
}

.cursor-pointer {
  cursor: pointer !important;
}


.z-index-1 {
  z-index: 1;
}

.custom-switch.custom-switch-large {
  padding-bottom: 1rem;
  padding-left: 2.25rem;
  text-align: right;

  & .custom-control-label {
    padding-left: .75rem;
    padding-top: 0.15rem;
    font-size: large;
    color: $brandSecondary;

    &::before {
      background-color: $white;
      border-radius: 1rem;
      height: 1.5rem;
      width: 2.5rem;
    }

    &::after {
      background-color: $brandSecondary;
      border-radius: .65rem;
      height: calc(1.5rem - 4px);
      width: calc(1.5rem - 4px);
    }
  }

  .custom-control-input:checked~.custom-control-label::after {
    background-color: $brandSecondary;
    transform: translateX(1rem);
  }
}

.h-77vh {
  height: 77vh;
}

.mh-72vh {
  max-height: 72vh;
}

.mh-77vh {
  max-height: 77vh;
}

.editor-editable {
  @media (min-width: 992px) {
    overflow: hidden;
  }

  @media (max-width: 576px) {
    padding-left: 0;

    p {
      margin-bottom: 0;
    }
  }
}

.editor-dev-tools {

  &.sidebar-collapse {
    @media (max-width: 992px) {
      flex: 0 0 100%;
      transform: rotate(360deg);
      height: auto;
    }

    @media (min-width: 992px) {
      flex: 0 0 100px;
      transform: rotate(90deg);
      height: 90px;
    }
  }

  .container-fields-collapse {
    display: none;

    @media (max-width: 991px) {
      display: block !important;
    }
  }

  @media (min-width: 992px) {
    flex: 0 0 300px;
  }
}

.icon-collapse-field {
  position: absolute;
  top: -5px;
  right: 40px;
  cursor: pointer;

  & svg {
    width: 34px;
    height: 34px;
  }
}

.custom-select:disabled {
  color: $blackPrimary;
}

.max-width-300 {
  max-width: 300px;
}

.text-truncate-tooltip {
  text-align: center;
  display: block;
  width: 100%;
  overflow-wrap: break-word;

  @media (max-width: 767px) {
    text-align: left;
    font-size: 16px;
  }
}

.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

.error-boundary_link {
  text-decoration: none;
  margin-top: 1rem;
  padding: 0.5rem 2rem;
  background-color: $brandPrimary;
  border-radius: 0.2rem;
  @include text-formatting(1rem, $normalText, $mainFont, $white);

  &:hover {
    color: $white;
  }
}

.image-center-align {
  text-align: center;
}

.list-group-item {
  &-paddings {
    padding: .5rem 4rem .5rem 0;
    background-color: $almostWhite;
  }

  &-custom {
    padding: .5rem 0;
  }
}

.privacy-policy-content {
  height: 70vh;
  overflow-y: auto;
}


/** For pdf renderer */
.pdf-viewer {
  width: 100%;
  height: 100%;
}

.pdf-viewer-container {
  min-height: 90%;
  overflow-y: scroll;

  &.editor-size {
    height: $editorMainHeight;
  }

  &.public-editor-size {
    overflow-x: auto;
  }
}

.pdf-pages-zoom-buttons {
  position: absolute;
  z-index: 100;

  button {
    background: white;
  }
}

.pdf__Page {
  margin: 5px auto 8px;
}

.react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  touch-action: pan-x pan-y;

  .pdf-pages-wrapper {
    margin: 0 auto;
    padding-bottom: 5px;
    transform-origin: 0 0;
  }
}

.public-main .react-pdf__Document {
  width: auto;
}

.editor-size .react-pdf__Document {
  touch-action: pan-x pan-y;

  .pdf-pages-wrapper {
    margin: 0 auto;
    transform-origin: 50% 0;
  }
}

.react-pdf__Page__textContent {
  border: 1px solid $darkGray;
  box-shadow: 5px 5px 5px 1px $mediumGray;
  border-radius: 5px;
  left: 0 !important;

  span {
    display: none;
  }
}

.react-pdf__Page__annotations.annotationLayer {
  height: 1px;
  padding: 0;
  margin: 0;
  display: none;
}

.react-pdf__Page__canvas {
  margin: 0;
}

.react-pdf__message {
  text-align: center;
  width: 100%;
}

.pdf-loader-message-content {
  position: relative;
  text-align: center;

  .sm-loader {
    min-height: 50px;
  }
}

.endAdornment-top-upset {
  top: unset !important
}

.selected-table-row-highlight {
  background-color: rgba(0, 0, 0, 0.04);
}

.collection-slides {
  position: absolute;
  display: flex;
  writing-mode: vertical-lr;
  z-index: 1499;

  &__button {
    width: 42px;
    height: 175px;
    max-height: 175px;
    border-radius: 5px;
    border-style: none;
    margin: 0 5px 5px 5px;
    padding: 5px;
    cursor: pointer;

    &:hover {
      background-color: $brandSecondary;
    }
  }

  &__active {
    background-color: $brandSecondary;
  }
}

.documents-limit-message {
  min-width: max-content;
  color: $blackPrimary;
  background-color: $white;
  font-size: $signatureHashFontSize;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
}

// TODO Note: strange solution)
.rmsc.dropdown-multi-select {
  --rmsc-primary: #4285f4;
  --rmsc-hover: #F2F7FF;
  --rmsc-border: #5A9EFF;
  --rmsc-gray: #5A9EFF;
  --rmsc-borderRadius: 0.25rem;
  --rmsc-h: 1.875rem;
}

/** --- */