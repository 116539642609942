@import '/src/variables.scss';
@import 'src/scss/helpers/mixins';

.signature-content {
  margin: 0 auto;
  background: white;
  box-shadow: 0 4px 17px rgba(184, 184, 184, 0.25);
  border-radius: $modalBorderRadius;
  max-width: 45vw;
  font-family: $mainFont;
}

.form-signature-wrapper {
  background: $veryLightGray;
  border-radius: 6px;
  align-items: center;
  height: 250px;
  padding-top: 1px;
  padding-bottom: 1px;

  &-typed {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-bottom: 2rem;
    &_input {
      overflow: visible;
      outline: none;
      border: none;
      background: transparent;
      height: 6rem;
      line-height: 3.5rem;
      width: 90%;
      text-align: center;
      @include text-formatting(3rem, $normalText, $typedSignatureFont, $blackColor);
    }
  }

  &-draw {
    .line-signature-canvas {
      background: $mediumGray;
      width: 80%;
    }

    .image-inside-modal {
      pointer-events: none;
      width: auto;
      max-height: 215px;
      max-width: 100%;
    }
  }

  &-upload {
    .upload-message {
      width: 35%;
      text-align: center;
    }
  }
}

.signature-canvas-wrapper {
  height: 215px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.signature-canvas {
  width: 100%;
  border: none;
  &.no-image-canvas {
    height: 215px;
  }
}

.divider {
  height: 1px;
  width: 90%;
  background-color: $mediumGray;
  margin: 1rem 2rem 0 2rem;
}

.signature-header {
  border-bottom: none;

  h5 {
    flex: 2;
    text-align: center;
    font-size: 18px;
    font-weight: $semiBoldText;
  }

  .btn-close {
    opacity: 1;
  }
}

.modal-signature-menu {
  text-align: center;
  box-shadow: 0 5px 5px rgb(184 184 184 / 14%);
  border-top: none;

  button {
    font-size: $mainFontSize;
    width: 20%;
    border: none;
    cursor: pointer;
    background: none;
  }

  .active-tab {
    color: $brandSecondary;
    border-bottom: 3px solid $brandSecondary;
    border-image: linear-gradient(to top, $brandSecondary 20%, rgba(0, 0, 0, 0) 20%);
    border-image-slice: 1;
  }
}

.signature-error {
  font-size: $mainFontSize;
  font-family: $mainFont;
}

.signature-footer {
  border-top: none;
  padding-top: 0;

  .footer-message {
    text-align: justify;
  }
}

.btn-clear {
  cursor: pointer;
  border: none;
  border-radius: 6px;
  background: $veryLightGray;
  font-size: $mainFontSize;
  color: $brandSecondary;
}

.btn-upload {
  background: $brandPrimary;
  color: white;
  width: 30%;
  font-weight: $semiBoldText;
  font-size: $mainFontSize;
}

.btn-sign {
  width: 40%;
  border: none;
  color: white;
  background: $brandPrimary;
  border-radius: 6px;
  font-weight: $semiBoldText;
  font-size: $mainFontSize;

  &:disabled {
    color: $mediumGray;
    background: $lightGray;
  }
}

@include max-screen(991) {
  .signature-content {
    max-width: 60vw;
  }
}

@include max-screen(768) {
  .modal-dialog.signature-modal {
    margin: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  .signature-content {
    width: 100%;
    height: 100%;
    max-width: none;
    border-radius: 0;
  }
}