@import 'src/variables';

.text-link {
    border: none;
    background: none;
    text-decoration: underline;
    vertical-align: top;

    .material-icons {
        vertical-align: middle;
        margin-right: 0.325rem;
    }
}

.green {
    color: $green;
}

.gray-dk {
    color: $darkGray;
}

.mw-80 {
    max-width: 80%;
}

.min-width-200 {
    min-width: 200px !important;
}