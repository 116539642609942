@import 'src/variables';
@import 'src/scss/helpers/mixins';

.sidebar-panel {
  max-height: 100%;
  overflow: auto;
  width: var(--sidebar-width);
  @include max-screen(1330) {
    position: absolute;
  }
}

.editor-navigator {
  height: 100%;
  border: 1px solid $lightGray;
  border-radius: 18px;
  background-color: $almostWhite;
  z-index: 100;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  &-wrapper {
    position: sticky;
    top: 0;
    width: auto;
    z-index: 102;
    height: auto;
    &.inactive {
      width: auto;
    }
    &.left-0 {
      .sidebar-panel {
        left: 0;
      }
    }
    &.right-0 {
      .sidebar-panel {
        right: 0;
      }
    }
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-title {
    font-family: $mainFont;
    font-size: $buttonFontSize;
    font-weight: $semiBoldText;
  }
}

.collection-page {
  .editor-navigator-wrapper {
    height: fit-content;
    position: initial;
    width: 25%;
  }
  .editor-main {
    justify-content: initial;
  }
}

.outline-sortable-container {
  min-height: $outlineSectionHeight;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-add-button {
    border: none;
    background: none;
  }
}

.collection-outline-element {
  max-width: calc(100% - 10px);
}

.toggle-button-show-sections {
  display: inline;
  cursor: pointer;
  border: none;
  background-color: $white;

  &:hover {
    background-color: $white;
  }
}

.toggle-button {
  border: none;
  background: none;

  &-inactive-public {
    transform: scale(-1, 1);
  }

  &-inactive {
    transform: scale(-1, 1);
    border: 1px solid $lightGray;
    border-radius: 20px;
    width: 58px;
    height: 58px;
    margin: 1rem 0 0 1rem;
    background-color: $almostWhite;
    flex-shrink: 0;
  }

  &-reverse {
    transform: scale(-1, 1);
  }

  &-inactive-reverse {
    transform: unset;
    margin: 1rem 1rem 0 0;

    &-public {
      transform: unset;
      margin: unset;
    }
  }
}

.search-form {
  position: relative;
  z-index: 110;
  &__button {
    position: absolute;
    right: 2rem;
    bottom: 37px;
  }

  &__layot {
    position: absolute;
    bottom: 30px;
    left: 100%;
    padding: 15px 15px;
    margin: 0;
    background-color: $almostWhite;
    border: 1px solid $lightGray;
    border-radius: 18px;
    width: min(100%, 400px);
  }
}

button.find-button {
  position: relative;
  background: none;
  border: none;
  padding: 3px 0.5rem;
  margin: 0;

  &:hover,
  &.active {
    background-color: #ffffff;
    box-shadow: none;

    &::after {
      position: absolute;
      content: "";
      display: block;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 1px;
      border-bottom: 3px solid $brandPrimary;
    }
  }
}

.editor-assignments {
  display: flex;
  flex-direction: column;
  background: $white;
}