@import 'src/variables.scss';

.select-signers {
  &-title {
    font-family: $mainFont;
    font-weight: $mediumText;
    font-size: $buttonFontSize;
    color: $blackColor;
  }

  &-close {
    border: none;
    background-color: unset;
  }

  &-card {
    margin: 1rem 0;
    border: 1px solid $mediumGray;
    border-radius: 13px;
    background-color: $almostWhite;
  }

  &-order {
    position: absolute;
    right: calc(100% + 15px);
    top: calc(50% - 1.5rem);
    line-height: 3rem;
    font-size: 3rem;
  }
}

.signer-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: $mainFont;
  font-weight: $mediumText;
  font-size: $mainFontSize;

  &-manager {
    color: $brandPrimary;
  }
  &-recipient {
    color: $brandSecondary;
  }
  &-cc {
    color: $darkGray;
  }

  &-cursor {
    cursor: move;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-edit-button {
    &.btn {
      width: unset;
    }
  }
}

.send-documents-sortable {
  display: flex;
  flex-direction: row;
  justify-content: start;
  cursor: grab;
}

.send-documents-final {
  width: 323px;

  &-signer {
    text-align: unset;
  }

  &-name {
    font-family: $mainFont;
    font-weight: $semiBoldText;
    font-size: $mainFontSize;
    color: $blackColor;
  }

  &-opacity {
    opacity: .5;
  }

  &-email {
    font-weight: $normalText;
  }

  &-container {
    text-align: start;
    border-bottom: 1px solid $mediumGray;

    &:last-child {
      border: none;
    }
  }
}