@import 'src/variables.scss';
@import 'src/scss/helpers/mixins';

.form-builder {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;

  &_sort_button_wrapper {
    margin-right: 12px;
    margin-top: 12px;
  }

  .active {
    border: 1px solid $blackColor;
  }

  &_section {
    margin: 16px 0;
    padding: 1rem 6rem;
    width: 700px;
    border-radius: 6px;
    border: 1px solid transparent;

    @include max-screen(576) {
      padding: unset;
      width: 100%;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;

      .section-name {
        margin: 0 8px;
        border-bottom: 1px solid transparent;
        word-break: break-all;
        @include text-formatting(1rem, $boldText, $mainFont, $blackColor);
      }

      .icon {
        cursor: pointer;
      }

      .input {
        @include text-formatting(1rem, $boldText, $mainFont, $blackColor);
        text-align: center;
        border: none;
        border-bottom: 1px solid $blackColor;
        outline: none;
      }
    }

    &_description {
      .text {
        text-align: center;
        cursor: pointer;
        border-bottom: 1px solid transparent;

        * {
          margin-bottom: 0;
        }

        &:hover {
          border-bottom: 1px solid $blackColor;
        }
      }
    }

    &_field-view {
      display: flex;
      width: 100%;

      .attachment-dropzone-wrapper {
        cursor: default;
      }

      .filepicker {
        pointer-events: none;

        .dz-message::before {
          display: none;
        }
      }

      .signature-field, .filepicker {
        background-color: $veryLightGray;
        border-color: $mediumGray;

        div {
          cursor: default;
        }

        &:hover {
          background-color: $veryLightGray;
        }
      }

      .field_wrapper {
        padding: 12px 8px;
        width: 100%;

        &:hover {
          background-color: $veryLightGray;
          border-radius: 6px;
        }
      }
    }
  }
}

.form-builder-paragraph.quill {
  max-width: none;
  padding: 0;
  margin-bottom: 0;

  .ql-container {
    font-family: $mainFont;
    font-size: $mainFontSize;
    font-weight: $normalText;
    color: $blackPrimary;
  }
}

.ql-tooltip {
  z-index: 1;
}

.pdf-expand-more-icon {
  position: absolute;
  right: 8px;
  bottom: 8px;
  transition: all .3s ease !important;
}