@import 'src/variables';
@import 'src/scss/helpers/mixins';


.greeting-wrapper {
  height: 100%;
  padding: 40px 15px calc(25px + var(--footer-height));
  overflow: auto;
  @include max-screen(767) {
    padding: 25px 15px calc(25px + var(--footer-height));
  }
}

.public-page__main {
  .greeting-wrapper {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
