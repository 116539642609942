@import 'src/variables';

.section-form-view__navigation-item-active,
.document-editor__navigation-item-active {
  z-index: 9999;
}

.form-row {
  margin-bottom: 1rem;

  label {
    text-align: right;
  }

  input, select, textarea {
    border-radius: 0;
  }
}

textarea {
  min-height: 60px;
}

select.form-control {
  &.empty-value,
  option[value=""] {
    color: $darkGray;
  }
  option:not([value=""]) {
    color: $blackPrimary;
  }
}

.footer {
  .multi-select {
    .dropdown:focus-within {
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
    }

    .dropdown-content {
      position: absolute;
      z-index: 1;
      top: auto;
      bottom: 100%;
      width: auto;
      min-width: 100%;
      padding-top: 0;
      padding-bottom: 8px;
    }

    .dropdown-heading {
      height: auto;
    }

    .dropdown-heading-value {
      height: calc(1.5em + .75rem + 2px);
      padding: .5rem .5rem .5rem 0;
      vertical-align: middle;
      color: $darkGray;
      font-size: 1rem;
      font-weight: $normalText;
      line-height: 1.5;
    }

    .select-item {
      white-space: nowrap;
      margin-bottom: 0;
    }
  }
}

.form-section {
  padding: 1rem 6rem;
  border-radius: 6px;
  margin: 0;
  border: 1px solid transparent;
  position: relative;
  cursor: default;

  @media (max-width: 1024px) {
    padding: 1rem 2rem;
  }

  &__active {
    border: 1px solid $blackColor;
    margin: 0;

    .form-section__buttons {
      display: flex;
    }
  }

  &__buttons {
    flex-direction: column;
    margin: 5px;
    top: 30px;
  }

  &__buttons,
  &__field-buttons {
    position: absolute;
    display: none;
    * {
      color: $blackColor;
    }
    *:disabled {
      opacity: 0.5;
    }
  }

  &__field-buttons {
    left: 0;
    margin-left: 100%;
    padding-left: 5px;
  }

  &__sort-field-button {
    position: relative;
    display: none;
  }

  &__field-label {
    color: $blackColor;
    input {
      font-size: 1rem;
    }
  }

  &__field-label:hover {
    .form-section__field-buttons {
      display: flex;
    }
  }

  &__add-new-button {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    &:before {
      content: "";
      position: absolute;
      top: 16px;
      width: 100%;
      border-top: 1px dashed $mediumGray;
    }

    button {
      font-size: $mainFontSize;
      line-height: 1.2;
      color: $blackColor;
      border: 1px solid $mediumGray;
      border-radius: 50px;
      background: white;

      &:hover {
        background: $veryLightGray;
      }
    }
  }

  &__field-ordering-active {
    display: block;
    min-height: 45px;
  }
}

.manager-form-view {
  li.list-group-item-custom {
    &:hover {
      background-color: transparent;
    }
  }
}

div[contenteditable][data-new-block-editor] {
  cursor: text;

  &:focus, &:active, &:focus-visible {
    border: none;
    border-width: 0;
    outline: none;
  }

  &:empty:after {
    content: attr(placeholder);
    opacity: 0.65;
  }
}

div[data-slate-node="element"][data-empty="true"] {
  position: relative;
  outline: none;

  &:last-child:not([data-disabled="true"])::after {
    content: "Start typing, paste text or type '/' for commands ...";
    opacity: 0.65;
    position: absolute;
    top: 0;
    padding: 12px 0;
  }
}

div[type="paragraph"],
div[type="bulleted-list"],
div[type="numbered-list"] {
  padding: 5px;
  border-radius: 6px;
  min-height: 35px;

  div[type="bulleted-list"],
  div[type="numbered-list"] {
    padding: 0;
    min-height: auto;
  }

  &:not([data-disabled="true"]):hover {
    background-color: $veryLightGray;

    .form-section__sort-field-button {
      display: block;
    }
  }
  &:has(.selected-field) {
    background-color: $veryLightGray;
    .editor-field-view-wrapper {
      box-shadow: inset 0 0 3px 4px $brandSecondaryHalfOpacity;
    }
  }
}

.form-paragraph-field {
  position: relative;

  &__buttons {
    padding: 0;
    top: 12px;
  }

  &:hover {
    .form-section__field-buttons {
      display: flex;
    }
  }
}