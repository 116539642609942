@import 'src/variables';
@import 'src/scss/helpers/mixins';

:root {
  --circle-size: 15px;
  --lize-width-size: 50px;
  --lize-height-size: 2px;
}

.step-manager {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  overflow: hidden;
  .breadcrumbs {
    padding: 14px 50px;
    min-height: 77px;
    @include max-screen(767) {
      padding: 14px 15px;
    }
    &__list {
      list-style: none;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 5px var(--lize-width-size);
    }
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding-left: 10px;
      padding-right: 10px;
      background: transparent;
      font-family: $mainFont;
      font-size: $mainFontSize;
      border: none;
      color: $blackPrimary;
      &::before {
        width: var(--circle-size);
        height: var(--circle-size);
        background: $mediumGray;
        border-radius: 50%;
        position: absolute;
        left: calc(var(--circle-size) / 2);
        top: calc(50% - (var(--circle-size) / 2));
      }
      &::after {
        content: '';
        width: var(--lize-width-size);
        height: var(--lize-height-size);
        background: $mediumGray;
        position: absolute;
        left: 100%;
        top: calc(50% - (var(--lize-height-size) / 2));
      }
      &--active {
        &::before {
          background: $brandPrimary;
        }
      }
      &--visited {
        padding-left: calc(var(--circle-size) * 2);
        &::before {
          content: '';
        }
      }
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      &:last-of-type {
        .breadcrumbs__button {
          padding-right: 5px;
          &::after {
            content: none;
          }
        }
      }
    }
  }
  &-component {
    display: grid;
    overflow: hidden;
  }
  &-child {
    max-width: 1320px;
    width: 100%;
    margin: 0 auto;
    padding: 70px 1rem;
    @include max-screen-height(1024) {
      padding: 40px 1rem 70px;
    }
  }
}