@import 'src/variables';
@import 'src/scss/helpers/mixins';

.Icon {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid rgba($documentFillOutStatusColor, .4);
    transform: rotate(-45deg);
  }
}

.Quarter {
  span {
    border-right-color: $documentFillOutStatusColor;
  }
}

.Half {
  span {
    border-right-color: $documentFillOutStatusColor;
    border-bottom-color: $documentFillOutStatusColor;
  }
}

.ThreeQuarters {
  span {
    border-right-color: $documentFillOutStatusColor;
    border-bottom-color: $documentFillOutStatusColor;
    border-left-color: $documentFillOutStatusColor;
  }
}