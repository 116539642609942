@import 'src/variables.scss';

button.notification-icon {
  background-color: $darkGray;
  border-radius: 50%;
  width: 32px;
  height: 32px;

  &:hover {
    background-color: $darkGray;
  }

  .notification-counter {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    transform: translate(8px, -8px);
    padding: 0;
    min-width: 20px;
    height: 20px;
    display: block;
    font-size: 0.9rem;
    line-height: 1.3;
    font-weight: $semiBoldText;
    border: 1px solid $darkGray;
    border-radius: 50%;
    background-color: $brandPrimary;
    color: white;
  }
}

.notification-list {
  width: 320px;

  ul {
    display: flex;
    flex-direction: column;

    li.notification-list-item {
      overflow: visible;
      white-space: normal;
      font-size: $smallFontSize;
      line-height: 1.2;
      font-family: $mainFont;
      font-weight: $mediumText;
      text-align: center;
      padding: 10px 0;
      margin-left: 5px;

      &.empty-item {
        min-height: 150px;
      }

      &::after {
        content: "";
        position: absolute;
        border-bottom: 2px solid $darkGray;
        height: 1px;
        width: 80%;
        display: block;
        bottom: 0;
      }

      &:last-of-type::after {
        display: none;
      }

      .menu-link {
        padding: 10px 5px;
      }
    }
  }

  .unread-message-dot {
    height: 5px;
    width: 5px;
    color: $brandPrimary;
    margin-right: 3px;
  }
}