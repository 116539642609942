@import 'src/variables';
@import 'src/scss/helpers/mixins';

.Name {
  font-size: $mainFontSize;
  line-height: 1.4em;
  font-weight: $mediumText;
  color: $blackColor;
  text-align: left;
  letter-spacing: 0;
}

.ArrowIcon {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 12px;
  top: calc(50% - 12px);
  &::before, &::after {
    content: '';
    position: absolute;
  }
  &::before {
    background: $blackColor;
    width: 16px;
    height: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &::after {
    width: 11px;
    height: 11px;
    border-right: 2px solid $blackColor;
    border-top: 2px solid $blackColor;
    top: calc(50% - 5.5px);
    right: 4px;
    transform: rotate(45deg);
  }
}

.AttachmentHelper {
  font-family: $mainFont;
  margin: 0 0 11px;
  font-size: 14px;
  font-weight: $normalText;
}

.AttachmentWrapper {
  margin-bottom: 11px;
  &:last-of-type {
    margin-bottom: 16px;
  }
}