@import 'src/scss/helpers/mixins';
@import 'src/variables';


.PublicEditor {
  padding: 20px;
}

.HeightWithNavbar {
  max-height: #{$mainHeightWithNavbar};
}

.HeightWithoutNavbar {
  max-height: #{$mainHeight};
}

.FormView {
  padding: 1.25rem 0;
}

.FullScreen {
  max-height: calc(#{$maxHeightWithNavbarWithoutFooter});
}

.PublicOutlinePanel {
  border: 1px solid $mediumGray;
  background-color: $veryLightGray;
  padding: 20px;
  width: 100%;
  z-index: 100;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  position: sticky;

}

.OutlinePanelInactive {
  width: 30%;
  min-width: auto;
}

.OutlinePanelText {
  font-family: $mainFont;
  font-size: $mainFontSize;
  color: $blackColor;
}

.OutlineHeader {
  font-weight: $semiBoldText;
}

.OutlineElement {
  font-weight: $normalText;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 5px;
}

.ActiveOutlineElement {
  color: $brandPrimaryFocused;
  svg path {
    fill: $brandPrimaryFocused;
  }
}