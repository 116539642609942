@import 'src/variables';

.search-field-button {
  position: absolute;
  top: 0;
  right: 0;
}
.search-field-results {
  position: absolute;
  top: 1px;
  right: 0;
  z-index: 1;
  border: 1px solid;
  min-width: 100%;
  max-width: max-content;
  &.form-control {
    width: min(800px, 90vw);
    max-height: 160px;
    overflow-y: auto;
    z-index: 112;
  }
  .search-field-results-row {
    padding: 0.25rem 1rem;
    &:hover,
    &.active {
      background-color: $brandSecondaryHalfOpacity;
    }
  }
}
.field-connection-icon {
  position: absolute;
  top: -25px;
  left: 90px;
  svg {
    height: 16px;
    color: $recipientFieldColor;
  }
}