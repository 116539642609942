@import 'src/variables';

.toolbar {
  visibility: hidden;
  width: 280px;
  margin-top: -6px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 40px;
  transition: opacity 0.75s;
  box-shadow: 0 7px 5px 5px rgba(0, 0, 0, 0.2);
  z-index: 105;

  @media (max-width: 780px) {
    width: 270px;
  }

  div input.input-toolbar-filter {
    width: 100%;
    padding-left: 5px;
    padding-bottom: 10px;
    border: none;
    outline: none;
    border-bottom: 1px solid $lightGray;
    font-size: 14px;
  }

  div ul.nav-tabs-toolbar {
    display: flex;
    list-style-type: none;
    padding: 0;

    li {
      padding: 3px 4px;
      font-size: 14px;
      cursor: pointer;
    }

    li.active {
      border-bottom: 2px solid rgb(1, 1, 194);
    }
  }

  ul.toolbar-list {
    list-style-type: none;
    padding: 0;

    .item-toolbar-editor {
      padding: 5px 10px;
      outline: none;
      background: inherit;
      font-size: 14px;
      color: $mediumGray;
      cursor: pointer;

      :focus {
        outline: none;
        background: inherit;
      }

      .icon {
        margin: 5px;
      }

      .command-horizontal svg {
        fill: $mediumGray;
      }

      .command-description {
        font-size: 12px;
      }

      &.table-toolbar-button {
        position: relative;
      }

      .popup {
        display: block;
        position: absolute;
        left: 0;
        background-color: white;
        padding: 6px 10px;
        border: 1px solid lightgray;
        height: fit-content;
        z-index: 1;
      }

      .select-table-size {
        width: 160px;

        .table-option {
          display: flex;
          margin: 5px 2px;
          gap: 3px;
          white-space: nowrap;
        }

        .table-input {
          display: grid;
          grid-template-columns: repeat(8, auto);
          gap: 1px;
        }

        .table-unit {
          width: 15px;
          height: 15px;
          border: 1px solid lightgray;
        }
      }
    }
  }
}

.modal-vertical-toolbar-editor {
  width: 100%;
  height: 110px;
  margin: 0;
  padding: 0;
  padding-left: 5px;
  overflow-y: scroll;
  list-style-type: none;

  &.image-settings {
    height: 170px;
    overflow-y: auto;
  }
}

.modal-toolbar-editor {
  width: fit-content;
  background: white;
  z-index: 5;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
}

.modal-select-toolbar-editor {
  width: 160px;
  list-style-type: none;
  height: 190px;
  z-index: 5;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
  overflow: scroll;
  padding: 0;
  margin: 0;
}

.btn-toolbar-editor {
  display: inline-block;
  width: 34px;
  height: 34px;
  padding: 5px;
  fill: $mediumGray;
  cursor: pointer;
  color: $blackColor;

  .icon-headline {
    font-weight: 600;
    font-size: 18px;
    color: inherit;
  }
}

.btn-toolbar-editor svg {
  fill: currentColor;
}

.draggable-toolbar-menu {
  background-color: transparent;
  position: absolute;
  width: 2px;
  min-height: 25px;
  height: 100%;
  margin-right: 2px;
  left: -24px;
  bottom: 0;

  .draggable-toolbar-wrapper {
    display: inline-block;
    position: absolute;
    z-index: 9;
    opacity: 1;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    border-radius: 1px;
    background-color: $white;

    .draggable-toolbar-button {
      user-select: none;
      transition: background 20ms ease-in 0s;
      cursor: grab;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      width: 22px;
      height: 22px;
      fill: $blackColor;
      background: $white;
    }

    svg {
      height: 100%;
      fill: currentColor;
    }
  }
}