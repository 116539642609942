@mixin max-screen($width) {
  @media only screen and (max-width: $width + "px") {
    @content
  }
}

@mixin text-formatting($fontSize, $fontWeight, $fontFamily, $color) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  font-family: $fontFamily;
  color: $color;
}

@mixin min-screen($width) {
  @media only screen and (min-width: $width + "px") {
    @content
  }
}


@mixin max-screen-height($height) {
  @media only screen and (max-height: $height + "px") {
    @content
  }
}

@mixin max-screen-and-max-height($width, $height) {
  @media only screen and (max-height: $height + "px") and (max-width: $width + "px") {
    @content
  }
}