@import 'src/variables';
@import 'src/scss/helpers/mixins';

h1.title {
  font-family: $accentFont;
  font-weight: $normalText;
  font-size: 24px;
  line-height: 1.71em;
  color: $blackColor;
  text-align: center;
  margin: 0;
  padding: 0;
}

h2.title {
  font-family: $mainFont;
  font-style: normal;
  font-weight: $semiBoldText;
  font-size: 16px;
  line-height: 1.4em;
  text-align: center;
  color: $blackColor;
  margin: 0;
  padding: 0;
  @include max-screen(767) {
    line-height: 1.55em;
  }
}