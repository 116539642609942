@import 'src/variables';

.skiptranslate {
  width: 1px;
  height: 1px;
  position: fixed;
  overflow: hidden;
  opacity: 0;
  left: -1px;
  top: -1px;
  display: none;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  display: none;
}

.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
  background: transparent!important;
  box-shadow: none!important;
}

.google-translate {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
  svg {
    fill: $blackColor;
  }
}

.gt-menu-item {
  display: flex!important;
  justify-content: flex-start!important;
  padding: 6px 16px!important;
  box-sizing: border-box!important;
  white-space: nowrap!important;
}