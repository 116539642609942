@import 'variables';

.selectable-group {
  &.disabled {
    position: inherit!important;
  }
  .selectable-selectbox {
    border-radius: 6px;
    background: rgba($brandSecondary, .2);
    border: 1px solid $brandSecondary;
  }
  .selectable-item {
    border-radius: 6px;
    &.selecting {
      background: rgba($brandSecondary, .1);
    }
    &.selected {
      background: rgba($brandSecondary, .2);
    }
  }
}

.sortable-element {
  z-index: 1000;
}

.drag-and-drop-element {
  .selectable-item {
    &.selecting input{
      background: rgba($brandSecondary, .1);
    }
    &.selected input{
      background: rgba($brandSecondary, .2);
    }
  }
}

.form-builder {
  .sortable-element {
    z-index: 1;
  }
}

.pdf-editor-sort-button {
  position: absolute;
  left: -25px;
}

.form-builder-view-switcher {
  translate: 0 -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 102;
  padding: 10px 0;
  border-radius: 0 0 5px 5px;
  &__button {
    font-family: $mainFont;
    border: 2px solid $brandPrimary;
    font-weight: $normalText;
    font-size: $mainFontSize;
    padding: 2px 0.5rem;
    margin: 0 3px;
    color: $blackColor;
    background-color: $white;
    min-height: unset;
    min-width: auto;
    border-radius: 6px;
    &.active {
      color: $white;
      background: $brandPrimary;
    }
  }
}