@import 'src/variables';

.public-page {
  overflow: hidden;
  &__header {
    height: $headerHeight;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  &__main {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - $headerHeight);
  }
  &__editor {
    flex: 1 1 auto;
    padding-bottom: var(--footer-height);
    display: flex;
    position: relative;
  }
}