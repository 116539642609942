@import 'src/variables';

.table-question {
  position: relative;

  &-body {
    counter-reset: tr;
    tr {
      counter-reset: td;
      &:first-child {
        .table-question-horizontal-resize-bar {
          height: 15px!important;
          &__bar {
            display: none;
          }
        }
        td:not(:first-child) {
          &::before {
            display: block;
            content: counter(td, upper-alpha);
            counter-increment: td;
            text-align: center;
          }
        }
      }
      &:nth-child(2) {
        td {
          font-weight: $semiBoldText;
        }
      }
      &:not(:first-child) {
        td:first-child {
          &::before {
            display: block;
            font-weight: $normalText;
            content: counter(tr);
            counter-increment: tr;
            text-align: center;
          }
        }
      }
    }
  }

  &-toolbar {
    position: absolute;
    top: -38px;
    left: 50%;
    display: flex;
    gap: 3px;
    translate: -50%;
    background: $white;
    box-shadow: 0 2px 3px 3px rgba($blackColor, .2);
    padding: 5px 3px;
    border-radius: 4px;
    &-button {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border-radius: 50%;
      padding: 2px;
      box-sizing: content-box;
      border: none;
      &:hover, &:focus {
        background: rgba($blackColor, .2);
      }
    }
  }

  &-cell {
    min-width: 50px;
    height: 50px;
    border: 1px solid $mediumGray;
    position: relative;
    overflow: hidden;
  }

  &-vertical-resize-bar {
    min-width: 50px;
    height: 15px;
    border: 1px solid $mediumGray;
    position: relative;
    &__bar {
      z-index: 1;
      width: 5px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background: $mediumGray;
      cursor: col-resize;
    }
  }
  &-horizontal-resize-bar {
    min-width: 25px;
    border: 1px solid $mediumGray;
    position: relative;
    &__bar {
      z-index: 1;
      width: 100%;
      height: 5px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: $mediumGray;
      cursor: row-resize;
    }
  }
  .editor-input-span-field {
    width: 100% !important;
  }
  &-cell-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    height: 100%;
    width: 100%;
    word-break: break-word;
    span[data-field="true"],
    &--text span {
      width: 100%;
    }
  }
}

