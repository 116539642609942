@import 'src/variables.scss';
@import 'src/scss/helpers/mixins';

.show-more-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;

  &_text {
    cursor: pointer;
    @include text-formatting(1rem, $normalText, $mainFont, $brandSecondary);
  }

  &_icon {
    cursor: pointer;
    color: $brandSecondary;
  }
}