@import 'src/variables.scss';
@import 'src/scss/helpers/mixins';

.document-navigation {
  padding: 1rem 0 0.25rem;

  &-dashboard-title {
    font-family: $mainFont;
    font-weight: $semiBoldText;
    font-size: 18px;
  }

  &-container {
    padding-bottom: 1.5rem;

    div {
      border-bottom: 1px solid $mediumGray;
    }

    &_link {
      @include text-formatting(0.95rem, $normalText, $mainFont, $blackColor);
      border: none;
      border-bottom: 3px solid transparent;
      cursor: pointer;
      background-color: transparent;
      padding: 0 1.5rem 0.5rem;
      flex-shrink: 0;

      @media (max-width: 768px) {
        padding: 0 0.5rem 0.5rem;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .active-tab {
    color: inherit;
    font-weight: $mediumText;
    border-bottom: 3px solid $brandPrimary;
  }
}