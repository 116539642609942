@import 'src/variables';
@import 'src/scss/helpers/mixins';

.header {
  height: $headerHeight;
  background-color: $blackColor;

  h3 {
    letter-spacing: 2px;
    @include text-formatting(1.75rem, $boldText, $accentFont, $white);

    @media (max-width: 767px) {
      font-size: 1.25rem;
      padding: 0.25rem 0;
    }
  }

  a {
    @include text-formatting(15px, $normalText, $mainFont, $white);

    text-decoration: none;
    border-bottom: 0.25rem solid transparent;
    padding: 1.05rem 1rem 1rem 1rem;

    @include max-screen(991) {
      padding: 1.05rem .5rem 1rem;
    }

    @media (max-width: 767px) {
      font-weight: $boldText;
      font-size: 1rem;
      padding: 0.5rem 0.5rem;
    }
  }

  .is-active {
    border-bottom: 0.25rem solid $brandPrimary;

    @media (max-width: 767px) {
      border: none;
    }
  }

  .material-icons {
    color: $white;
    width: 20px;
    height: 20px;
  }

  @media (max-width: 370px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 767px) {
    padding: 0.4rem 0.75rem 0.4rem 0.75rem;
  }

  .expand-more-icon {
    cursor: pointer;
  }
}

.wrapper-company-logo {
  display: flex;
  align-items: center;
  height: $headerHeight;
}

.main-company-logo {
  height: 70%;
}