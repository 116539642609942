@import 'src/variables';

@keyframes sync-loader {
    0% {
        box-shadow: 0 1em 0 -0.2em currentColor;
    }
    100% {
        box-shadow: 0 2em 0 -0.2em currentColor;
    }
}

.sync-loader {
    animation: sync-loader 0.6s ease-in-out alternate infinite;
    display: inline-block;
    width: 1em;
    height: 1em;
    color: $mediumGray;
    vertical-align: middle;
    pointer-events: none;
    border-radius: 50%;
    box-shadow: 0 1em 0 -0.2em currentcolor;
    position: relative;
    animation-delay: 0.22s;
    top: -1em;
    font-size: 1.5rem;
    &:after,
    &:before {
        content: '';
        position: absolute;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        box-shadow: inherit;
        animation: inherit;
    }
    &:before {
        left: -1em;
        animation-delay: 0.38s;
    }
    &:after {
        right: -1em;
        animation-delay: 0.11s;
    }
}
