@import 'src/variables';

h1, h2, h3, h4, h5, h6, label, .label {
    font-family: $mainFont;
    font-weight: $semiBoldText;
    color: $blackPrimary;
    margin-top: 0;
}

h1 {
    font-size: 1.5rem; // 24px
}

h2 {
    font-size: 1.25rem; // 20px
}

h3 {
    font-size: 1.14rem; // 18px
}

h4 {
    font-size: 1rem; // 16px
}

h5 {
    font-size: .875rem; // 14px
}

h6 {
    font-size: .75rem; // 12px
}

.fs-14 {
    font-size: 0.875rem; // 14px
}

label,
.label,
.multi-select {
    font-size: 0.875rem; // 14px
}

.multi-select {
    svg {
        width: 1rem;
    }

    .dropdown-heading-value {
        color: $brandSecondary;
    }

    .item-renderer span {
        padding-left: 0.875rem;
    }
}

input, select, textarea {
    &.form-control {
        font-size: 1rem; // 16px
        color: $blackColor;
        font-family: $mainFont;
        &::placeholder {
            font-weight: $normalText;
            line-height: 1.25;
            color: $darkGray;
            opacity: 1;
        }
    }

    &:disabled {
        background-color: $veryLightGray;
        color: $darkGray;
        opacity: 1;
    }
}

select {
    & option:disabled {
        color: $darkGray;
        background-color: $veryLightGray;
    }
}

.jodit_wysiwyg {
    font-family: $mainFont;
    font-size: 0.875rem;
}

a, button {
    &.icon-link {
        vertical-align: top;
        font-size: 0.875rem;

        .material-icons {
            vertical-align: middle;
            margin-right: 0.325rem;
        }
    }

    &.text-link {
        font-size: 1rem;
        text-decoration: underline;
        color: $darkGray;
    }

    &:disabled {
        background-color: $veryLightGray;
    }
}
