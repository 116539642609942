@import 'src/variables';
@import 'src/scss/helpers/mixins';

.Intro {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
}

.JustifyContentStart {
  padding-top: 22vh;
  justify-content: start;
};

.Description {
  font-family: $mainFont;
  font-weight: $normalText;
  font-size: $mainFontSize;
  line-height: 1.53em;
  color: $blackColor;
  text-align: center;
  margin-bottom: 12px;

  &Small {
    max-width: 215px;
  }

  &Large {
    max-width: 430px;
    font-size: 18px;
    line-height: 1.3em;
  }

  @media (max-width: 576px) {
    &SmallMobile {
      max-width: 215px;
    }

    &LargeMobile {
      max-width: 430px;
    }
  }
}