.document-thumbnail {
  position: relative;
  &:hover, &:focus {
    button {
      opacity: 1;
    }
  }
  button {
    position: absolute;
    right: 3px;
    top: 3px;
    opacity: 0;
    transition: opacity .3s ease;
  }
}