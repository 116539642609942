@import 'src/scss/helpers/mixins';
@import 'src/variables';

.public-editor {
  padding: 20px;
  transition: all .3s ease;
  position: relative;
  &--fullscreen {
    max-height: calc(#{$maxHeightWithNavbarWithoutFooter});
  }
  &--height-with-navbar {
    max-height: #{$mainHeightWithNavbar};
  }
  &--height-without-navbar {
    max-height: #{$mainHeight};
  }
}