@import 'src/variables';
@import 'src/scss/helpers/mixins';

.dashboard-dropzone {
  margin-top: 25px;
  background: $veryLightGray;
  border: 1px dashed $cornflowerBlue;
  border-radius: 6px;
  cursor: pointer;
  caret-color: transparent;
  min-height: 190px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  @include max-screen(767) {
    min-height: 180px;
  }

  h3 {
    color: $blackColor;
    line-height: 1.45em;
    font-size: 24px;
    font-weight: $normalText;
    margin-top: 15px;
    margin-bottom: 25px;
    @include max-screen(992) {
      font-size: 22px;
    }
    @include max-screen(610) {
      font-size: 18px;
    }
  }

  &__errors-list {
    margin-top: 25px;
    margin-bottom: 0;
    list-style: none;
    border: 1px dashed $inputError;
    border-radius: 6px;
    font-family: $mainFont;
    color: $blackColor;
    line-height: 1.45em;
    padding: 15px;
  }

  &__error-file-name {
    font-size: 16px;
    font-weight: $mediumText;
  }

  &__errors-message-list {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: $mainFontSize;
    font-weight: $normalText;
  }
}
