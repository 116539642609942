.table {
  margin: 4px 0;
  overflow: auto;
  border: solid 1px #000;
  table-layout: fixed;
}
.table-tr {
  border: none;
}
.table-td {
  padding: 0;
  box-sizing: border-box;
  border: solid 1px #000;
  vertical-align: baseline;
  position: relative;
  min-width: 30px;
  padding: 0.5rem 0.5rem;
}
.table-body {
  overflow: auto;
}

[data-slate-editor="true"] .table .d-inline,
[data-slate-editor="true"] .table .inline-field-wrapper {
  white-space: normal;
  max-width: 100%;
}

.table .text-field-editable,
.table .select-field-editable {
  min-width: min(max(100%, 10px), 200px);
  width: min(max(100%, 10px), 200px);
}