@import 'src/scss/helpers/mixins';

.payment-icon {
  display: block;
  width: 36px;
  height: auto;
  border-radius: 4px;
  @include max-screen(567) {
    height: 36px;
    width: 40px;
    object-fit: cover;
  }
}