@import 'src/variables';

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    z-index: 2;
    position: absolute;
    top: calc(100% - 1px);
    width: 100%;
    border: 1px solid $mediumGray;
    border-radius: .25rem;
    color: $blackColor;
    font-size: 1em;
    background-color: $white;
    max-height: 310px;
    overflow-y: scroll;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: $mediumGray;
}
