@import 'src/variables.scss';

.attachments-field-name {
  font-family: $mainFont;
  font-weight: $normalText;
  font-size: $mainFontSize;
  line-height: 1.45em;
  color: $blackColor;
  margin-bottom: 8px;
  span {
    color: $brandPrimary;
  }
}

.attachments-required-message {
  font-family: $mainFont;
  font-weight: $normalText;
  font-size: $mainFontSize;
  line-height: 1.45em;
  margin-top: 10px;
  color: $inputError;
  display: flex;
  align-items: center;
  svg {
    display: block;
    flex-shrink: 0;
  }
  span {
    margin-left: 7px;
  }
}

.dz-message {
  position: relative;
  padding: 13px 18px;
  max-width: 80%;
  &::before {
    content: "";
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyMiAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjY2NjYgNi4yNDk5MlYxNy4yNzA4QzE0LjY2NjYgMTkuMzg4NyAxMy4wMjU4IDIxLjEwNDEgMTAuOTk5OSAyMS4xMDQxQzguOTc0MDggMjEuMTA0MSA3LjMzMzI1IDE5LjM4ODcgNy4zMzMyNSAxNy4yNzA4VjUuMjkxNThDNy4zMzMyNSAzLjk2OTA4IDguMzU5OTIgMi44OTU3NSA5LjYyNDkyIDIuODk1NzVDMTAuODg5OSAyLjg5NTc1IDExLjkxNjYgMy45NjkwOCAxMS45MTY2IDUuMjkxNThWMTUuMzU0MUMxMS45MTY2IDE1Ljg4MTIgMTEuNTA0MSAxNi4zMTI0IDEwLjk5OTkgMTYuMzEyNEMxMC40OTU4IDE2LjMxMjQgMTAuMDgzMyAxNS44ODEyIDEwLjA4MzMgMTUuMzU0MVY2LjI0OTkySDguNzA4MjVWMTUuMzU0MUM4LjcwODI1IDE2LjY3NjYgOS43MzQ5MiAxNy43NDk5IDEwLjk5OTkgMTcuNzQ5OUMxMi4yNjQ5IDE3Ljc0OTkgMTMuMjkxNiAxNi42NzY2IDEzLjI5MTYgMTUuMzU0MVY1LjI5MTU4QzEzLjI5MTYgMy4xNzM2NyAxMS42NTA4IDEuNDU4MjUgOS42MjQ5MiAxLjQ1ODI1QzcuNTk5MDkgMS40NTgyNSA1Ljk1ODI1IDMuMTczNjcgNS45NTgyNSA1LjI5MTU4VjE3LjI3MDhDNS45NTgyNSAyMC4xODQxIDguMjEzMjUgMjIuNTQxNiAxMC45OTk5IDIyLjU0MTZDMTMuNzg2NiAyMi41NDE2IDE2LjA0MTYgMjAuMTg0MSAxNi4wNDE2IDE3LjI3MDhWNi4yNDk5MkgxNC42NjY2WiIgZmlsbD0iIzRDOTVGQyIvPgo8L3N2Zz4K");;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: 22px;
    height: 100%;
    left: -5px;
    top: 0;
  }
  span {
    display: block;
    font-family: $mainFont;
    font-weight: $normalText;
    font-size: $mainFontSize;
    line-height: 1.45em;
    color: $blackColor;
  }
}

.dz-preview {
  margin-top: 5px;
  font-family: $mainFont;
  font-weight: $normalText;
  font-size: $mainFontSize;
  line-height: 1.45em;
  color: $blackColor;

  &.dz-error {
    .dzm-error-message-wrapper {
      color: $inputError;
      display: flex;
    }
    .dzm-details {
      color: $inputError;
    }
    .dzm-file-icon {
      svg path {
        fill: $inputError;
      }
    }
  }

  .dzm-remove-file {
    margin: 0 0 0 auto;
  }

  .dzm-details {
    display: flex;
    align-items: center;
  }

  .dzm-filename {
    display: flex;
    align-items: center;
    .dzm-name {
      margin-left: 5px;
      max-width: 268px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .dzm-error-message-wrapper {
    display: none;
    margin-top: 10px;
    align-items: center;
    .dzm-error-message {
      margin-left: 7px;
    }
  }
}

.slate-attachment {
  span {
    display: inline-block;
  }
}

[data-slate-editor=true] {
  .slate-attachments-list {
    &[data-attachments-empty=true]::after {
      content: none;
    }
    .upload-button {
      background: $veryLightGray;
      min-height: 48px;
      border: 1px dashed $cornflowerBlue;
      border-radius: 6px;
      cursor: pointer;
      caret-color: transparent;
      text-align: center;
      font-size: $mainFontSize;
      font-family: $mainFont;
      font-style: normal;
      color: $blackColor;
      font-weight: 400;
      line-height: 1.45em;
    }
  }
}

.slate-field-attachment-name {
  max-width: 512px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}