@import 'src/variables';

.collection-page {
  .editor-size {
    height: $editorCollectionHeight;
  }
  .sections-swiper {
    height: calc($editorMainHeightMobile);
    @include max-screen(1399) {
      height: calc($editorCollectionHeight + 25px);
    }

    .swiper-pagination {
      bottom: 0;
    }
  }
}

.collection-slider {
  display: grid;
  .sections-swiper {
    height: 100%;
    display: flex;
    width: 100%;
    max-width: 100%;
  }
}