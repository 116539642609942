@import '/src/variables';

progress {
  background: none;
  display: block;
}

progress,
.progress {
  width: 100%;
  height: 4px;
  background-color: $brandTintShadow;
}

progress::-webkit-progress-bar {
  background: $brandTintShadow;
}

.progress-bar,
progress::-webkit-progress-value {
  background: $brandPrimary;
  background-color: $brandPrimary;
}