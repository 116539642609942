@import '/src/variables.scss';

.document-info {
  max-width: 500px;

  &-row {
    font-family: $mainFont;
    font-size: $mainFontSize;
    font-weight: $mediumText;
    color: $blackColor;
    border-bottom: 1px solid $mediumGray;
    margin: 0.75rem;
    padding-bottom: 0.75rem;
  }
}