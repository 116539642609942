@import 'src/variables';
@import 'src/scss/helpers/mixins';

.tab-navigation {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  min-height: 48px;
  border-top: 1px solid $lightGray;
  transition: all .3s ease;
  @include max-screen(767) {
    border-top: none;
  }
  svg {
    margin-right: 4px;
  }
  &__tab {
    font-family: $mainFont;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 126px;
    padding: 11px 8px 12px;
    margin: 0 35px;
    white-space: nowrap;
    @include max-screen(767) {
      min-width: 95px;
      margin: 0 20px;
    }
    @include max-screen(567) {
      min-width: 25px;
      margin: 0 15px;
      svg {
        margin-right: 0;
      }
      span {
        display: none;
      }
    }
    span {
      font-weight: $mediumText;
    }
    &--active {
      color: $brandSecondary;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        display: block;
        height: 3px;
        width: 100%;
        background-color: currentColor;
        left: 0;
        bottom: 0;
      }
      svg path {
        fill: $brandSecondary;
      }
    }
    &--disabled {
      color: $mediumGray;
      cursor: not-allowed !important;
    }
  }
}