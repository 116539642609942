@import 'src/variables.scss';

/* Filepicker CSS */
.filepicker { font-family: sans-serif; }

div.filepicker {
    min-height: 48px;
    border: 1px dashed $cornflowerBlue;
    background: $almostWhite;
    border-radius: 6px;
    justify-content: center;
    display: flex;
    cursor: pointer;
    caret-color: transparent;
    text-align: center;
}

div.filepicker .dz-message {
    align-self: center;
    font-size: $mainFontSize;
    font-family: $mainFont;
    font-style: normal;
    font-weight: $semiBoldText;
    color: $blackColor;
}
.p-attachments {
    padding-top: 30px;
}
.attachments-delete-option {
    font-size: 10px;
    cursor: pointer;
    position: absolute;
    top: -7px;
    right: 0;
    opacity: 0.5;

    & svg {
        font-size: 1.2rem;
    }

    &:hover {
        opacity: 1;
        & svg {
            color: $inputError;
        }
    }
}

/* Icon */
.filepicker-file-icon {
    position: relative;
    display: block;
    margin: 0 0 2px 0;
    color: $blackPrimary;
    cursor: pointer;
    height: 38px;
    width: 40px;

    &::before {
        position: absolute;
        top: 0;
        width: 35px;
        height: 40px;
        left: 0;
        content: '';
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAa9JREFUeF7tm9FNwzAURU8nACaAEWATRoAJEBMAE8AGsBGswASwAehJrVSpCOXexpGt3H77Os8nfqeJ5GxY+W+z8vUTANkBHoFn4Bq48OL/pr6Be+CtwdwHUzot8ALcLVDc7RIQHABfwClwBXw0APGzN2dzCA6AXYFOdgqvfQA1vikEZxFLAaiFv26JNYPQM4Cq7aY1hN4B1AZoCmEEAE0hjAKgGYSRADSBMBqA2SGMCGBWCKMCmA1CjwDqZehk4qP20X+RPQJ4BB6mPDP/MUZ+YuwRQK2rINTdPRdB1O45UzK9AlDWsBtrvaMEgIHaIm1cR41YdWUHqJgBi7RxHTVi1ZUdoGLODkgLxAGWbIxWUyNWXZGgijkSjAQjQUs2RqupEauuSFDFHAlGgpGgJRuj1dSIVVckqGKOBCPBSNCSjdFqasSqKxJUMUeCkWAkaMnGaDU1YtUVCaqYI8FIMBK0ZGO0mhqx6ooEVcyRICjn+Ay+VuQSeAc+1Q+5nBY45hyftToh9LQ9Yjc54gCoyd1zfJMLEwfWna/P7Kou6ecCkC7S8+AA6PnuLFHb6nfALyG4jkGRd5E6AAAAAElFTkSuQmCC");
        background-size: cover;
    }
    &::after {
        font-size: 8px;
        line-height: 1.25;
        position: absolute;
        top: 16px;
        left: 1px;
        padding: 0 2px;
        content: attr(data-filetype);
        text-align: right;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        color: $white;
        background-color: $blackPrimary;
        min-width: 25px;
    }
    .fileCorner {
        position: absolute;
        top: -7px;
        left: 22px;
        width: 0;
        height: 0;
        border-width: 11px 0 0 11px;
        border-style: solid;
        border-color: $white transparent transparent $inputError;
    }
}
.dz-error-message {
    color: red;
}

.dropzone,
.dropzone * {
    box-sizing: border-box;
}

.dropzone {
    position: relative;
    white-space: normal;

    .dz-preview {
        position: relative;
        width: 70px;
        display: inline-flex;
        flex-direction: column;
        vertical-align: top;

        .dz-image {
            width: 80%;
            overflow: hidden;
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAa9JREFUeF7tm9FNwzAURU8nACaAEWATRoAJEBMAE8AGsBGswASwAehJrVSpCOXexpGt3H77Os8nfqeJ5GxY+W+z8vUTANkBHoFn4Bq48OL/pr6Be+CtwdwHUzot8ALcLVDc7RIQHABfwClwBXw0APGzN2dzCA6AXYFOdgqvfQA1vikEZxFLAaiFv26JNYPQM4Cq7aY1hN4B1AZoCmEEAE0hjAKgGYSRADSBMBqA2SGMCGBWCKMCmA1CjwDqZehk4qP20X+RPQJ4BB6mPDP/MUZ+YuwRQK2rINTdPRdB1O45UzK9AlDWsBtrvaMEgIHaIm1cR41YdWUHqJgBi7RxHTVi1ZUdoGLODkgLxAGWbIxWUyNWXZGgijkSjAQjQUs2RqupEauuSFDFHAlGgpGgJRuj1dSIVVckqGKOBCPBSNCSjdFqasSqKxJUMUeCkWAkaMnGaDU1YtUVCaqYI8FIMBK0ZGO0mhqx6ooEVcyRICjn+Ay+VuQSeAc+1Q+5nBY45hyftToh9LQ9Yjc54gCoyd1zfJMLEwfWna/P7Kou6ecCkC7S8+AA6PnuLFHb6nfALyG4jkGRd5E6AAAAAElFTkSuQmCC");
            background-size: cover;

            img {
                width: 100%;
                background-color: $white;
            }
        }

        .dz-details {
            font-size: 0.8em;
            overflow-wrap: anywhere;

            .dz-filename {
                overflow: hidden;
                height: 28px;
            }
        }

        .dz-progress {
            display: none;
            height: 4px;
            border: 0 solid $veryLightGray;
            border-radius: 4px;
            background: $veryLightGray;

            .dz-upload {
                display: none;
                height: 100%;
                width: 0;
                max-width: 100%;
                background: $blackPrimary;
                border-radius: 4px;
            }
        }

        .dz-error-message {
            color: $inputError;
            display: none;
            position: absolute;
            max-height: 70px;
            overflow: hidden;
            width: 100%;
            background: $veryLightGray;
        }
    }
}

.dropzone .dz-preview .dz-error-mark,
.dropzone .dz-success-mark {
    position: absolute;
    display: none;
    right: 0;
    top: 0;
    width: 16px;
    height: 16px;
    margin-right: 0;
}
.dropzone .dz-error .dz-error-message,
.dropzone .dz-error .dz-preview .dz-error-mark {
    display: block;
    font-size: 9px;
    line-height: 10px;
    padding: 0 3px;
}
.dropzone .dz-success .dz-success-mark {
    display: block;
    background-color: $brandSecondary;
    border-radius: 8px;
}
.dropzone .dz-success .dz-remove {
    display: none;
}
.dropzone .dz-error-mark .fa {
    font-size: 0.8rem;
    width: 16px;
    height: 16px;
    line-height: 1rem;
    color: $inputError;
}

.pdf-attachments {
    overflow: hidden;
    background: white;
    .dz-preview {
        .dz-message {
            display: block;
            position: relative;
            padding: 0 8px;
            max-width: 100%;
        }
    }
    .attachments-file-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        word-break: break-all;
        overflow-wrap: break-word; // for Print view
        font-size: inherit;
        line-height: 1.2em;
        display: block;
        padding-left: 8px;
    }
}
.disable-pdf-attachments,
.disable-pdf-attachments.form-control {
  background-color: $lightGray;
  color: $darkGray;
  opacity: 1;
}
.custom-attachment-wrapper {
  &:focus-visible {
    outline: 0;
  }
  &:focus {
    outline: 0;

    div.filepicker {
      background: $veryLightGray;
      border-color: $brandPrimaryFocused;
      box-shadow: none;
      outline: 0;
    }
  }
}