@import 'src/variables';

.form-tab-help-text {
  font-weight: $normalText;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin: 4px 0 0 0;
  font-family: $mainFont;
  &--error {
    color: $inputError;
  }
}

.public-form-field {
  &.selected-field {
    label {
      background-color: $white;
    }
    &:not(.light-red-highlight) {
      input {
        box-shadow: inset 0 0 3px 2px $inputError;
        border-radius: 4px;
      }
      fieldset {
        border-color: $inputError;
      }
    }
    &.light-red-highlight {
      input {
        box-shadow: inset 0 0 3px 3px $lightRedColor;
        border-radius: 4px;
      }
      fieldset {
        border-color: $lightRedColor;
      }
    }
  }
}