@import 'scss/helpers/mixins';

:root {
  --sidebar-width: calc(368px + 2rem);
}

@media screen and (max-width: 1600px)  {
  :root {
    --sidebar-width: calc(323px + 2rem);
  }
}
@media screen and (max-width: 1600px)  {
  :root {
    --sidebar-width: calc(323px + 2rem);
  }
}
@media screen and (max-width: 1500px)  {
  :root {
    --sidebar-width: calc(295px + 2rem);
  }
}
@media screen and (max-width: 1440px)  {
  :root {
    --sidebar-width: calc(239px + 2rem);
  }
}
@media screen and (max-width: 1330px)  {
  :root {
    --sidebar-width: calc(225px + 2rem);
  }
}

.editor-container {
  height: 100%;
  overflow: auto;
  display: grid;
  grid-template-rows: 100%;
  &--public {
    height: auto;
    margin-bottom: var(--footer-height);
  }
  &.one-column {
    grid-template-columns: 1fr;
  }
  &.two-columns {
    grid-template-columns: var(--sidebar-width) 1fr;
    @include max-screen(1330) {
      grid-template-columns: 1px 1fr;
    }
  }
  &.three-columns {
    grid-template-columns: var(--sidebar-width) 1fr var(--sidebar-width);
    @include max-screen(1330) {
      grid-template-columns: 1px 1fr 1px;
    }
  }
}

.editor-wrapper {
  display: grid;
  height: 100%;
  &.one-row {
    grid-template-rows: 1fr;
  }
  &.two-rows {
    grid-template-rows: auto 1fr;
  }
  &.three-rows {
    grid-template-rows: auto 1fr auto;
  }
}