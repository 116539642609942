@import 'src/variables';

.branding-logo-image {
  width: 150px;
  height: 150px;
  border: 1px solid $cornflowerBlue;
  background: transparent;
  padding: 0;
  border-radius: 6px;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.branding-logo-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 335px;
  background: $almostWhite;
  border: 1px dashed $cornflowerBlue;
  text-align: center;
  padding: 11px 40px;
  font-family: $mainFont;
  font-size: $mainFontSize;
  font-weight: $normalText;
  color: $blackColor;
  cursor: pointer;
  border-radius: 6px;
  transition: all .3s ease;
  svg {
    fill: $brandSecondary;
  }
  &:hover {
    background: rgba($brandSecondary, .2);
  }
}

.branding-logo-upload-button {
  margin: 0 auto;
  input {
    opacity: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
  }
}

.branding-logo-error {
  margin: 15px 0 0;
  text-align: center;
  font-size: $smallFontSize;
  color: $inputError;
}

.branding-logo-modal {
  display: flex;
  flex-direction: column;
}

.branding-logo-preview {
  display: block;
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  border: 1px solid $cornflowerBlue;
  border-radius: 5px;
}

.branding-select-field {
  font-family: $mainFont;
  font-weight: $normalText;
  font-size: $mainFontSize;
  color: $blackColor;
  width: 100%;
  border: 1px solid $mediumGray;
  padding:  11px .75rem;
  border-radius: 0.25rem;
  appearance: none;
  cursor: pointer;
}