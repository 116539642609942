@import 'src/variables';

.page-settings-modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  div {
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 15px 0;
  }

  &__reminder-input {
    display: inline;
    width: 50px;
    border-radius: 5px;
    margin: 0 5px;
    border: 1px solid $brandSecondary;
    text-align: center;

    &:hover, &:focus {
      border-color: $brandSecondary;
      outline: none;
    }
  }

  label {
    color: $blackColor;
    font-weight: 500;
    font-size: 16px;
  }
}