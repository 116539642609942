@import 'src/scss/helpers/mixins';
@import 'src/variables';

.button-main {
  background-color: $brandPrimary;
  border: none;
  border-radius: 0.4rem;

  @include min-screen(576) {
    &.btn {
      width: $publicButtonWidth;
      height: 36px;
    }
  }

  @include max-screen(576) {
    display: inline-block;
    padding: 0;
    &.btn {
      width: 100%;
      min-width: 100px;
      height: 28px;
    }
  }

  &:hover {
    background-color: $brandPrimary;
    box-shadow: 0 7px 8px 0 $brandPrimaryShadow;
  }

  &:focus {
    background-color: $brandPrimary;
    box-shadow: none;
    outline: 0;
  }

  &:active {
    background-color: $brandPrimaryFocused;
  }
}
