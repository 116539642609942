@import 'src/variables';

.drag-and-drop-container {
  max-width: 100%;
  position: relative;
}
.drag-and-drop-element {
  display: flex;

  input, textarea, select {
    width: 100%;
    height: 100%;
    min-height: 20px;
    min-width: 35px;
    padding: 5px;
    line-height: normal;
    font-size: $pdfFieldFontSize;
  }
  input {
    &.print-checkbox-input {
      min-width: 20px;
    }
  }
  .pdf-attachments {
    min-height: 20px;
    min-width: 35px;
    padding: 0 5px;
  }
  textarea, select {
    padding: 0 5px;
  }
  input[type=radio] {
    min-width: 20px;
    width: 20px;
    height: 20px;
    &:focus {
      outline: 0;
    }
  }
}
.drag-and-drop-element-wrapper {
  position: absolute;
  display: inline-block;
  left: 0;
  top: 0;
  z-index: 100;
  outline: none;
  input {
    &::placeholder {
      text-align: center;
    }
  }
  &:hover {
    .pdf-field-buttons {
      display: flex;
    }
  }
}

.drag-and-drop-cursor,
.drag-and-drop-cursor--option {
  cursor: move;
  position: absolute;
  width: 30px;
  height: 20px;
  top: calc(50% - 10px);
  left: -30px;
  display: flex;
  justify-content: center;
  align-items: center;

  &-recipient {
    color: $recipientFieldColor;
    fill: $recipientFieldColor;
  }
  &-manager {
    color: $managerFieldColor;
    fill: $managerFieldColor;
  }
}

.drag-and-drop-sidebar {
  padding: 20px;
}
.drag-and-drop-sidebar-info {
  font-size: .8rem;
  line-height: normal;
  text-align: center;
  color: $darkGray;
  padding: 0 20px;
  margin: 20px 0 40px;
}
.drag-and-drop-button {
  border: 1px solid $mediumGray;
  border-radius: 6px;
  text-align: center;
  padding: 6px 5px;
  font-size: $pdfFieldFontSize;
  width: 100%;
}
.drag-and-drop-button-wrapper {
  margin: 12px 5px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:last-of-type {
    margin-bottom: 3px;
  }
}
.drag-and-drop-textarea {
  width: 100%;
  resize: none;
}
.drag-and-drop-icon {
  width: 28px;
  margin-right: 10px;
}

.resizable-wrapper {
  .drag-and-drop-checkbox-wrapper {
    position: relative;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;

    &:has(input[type="checkbox"]:focus):has(.pdf-field-recipient),
    &:hover:has(.pdf-field-recipient),
    &:active:has(.pdf-field-recipient),
    &:focus:has(.pdf-field-recipient) {
      box-shadow: inset 0 0 4px 2px $recipientFieldColor;
    }
    &:has(input[type="checkbox"]:focus):has(.pdf-field-manager),
    &:hover:has(.pdf-field-manager),
    &:active:has(.pdf-field-manager),
    &:focus:has(.pdf-field-manager) {
      box-shadow: inset 0 0 4px 2px $managerFieldColor;
    }

    input[type="checkbox"] {
      height: 100%;
      width: 100%;
      display: inline-block;
      border: 0 solid $white;
      margin: 0;
      padding: 5px;
      background-color: $white;
      background-image: none;
      opacity: 0.1;
      border-radius: 6px;
      cursor: pointer;
      min-width: 18px;
      min-height: 18px;
      &:disabled {
        background-color: $lightGray;
      }
    }
    input[type="checkbox"]:not(:checked) {
      opacity: 1;
    }

    .drag-and-drop-checkbox-checked {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      opacity: 1;
      background-color: $white;
      img {
        max-width: 100%;
        height: auto;
        max-height: 100%;
        vertical-align: top;
        margin: 2% auto;
      }
    }
    &:has(input[type="checkbox"]:disabled) {
      .drag-and-drop-checkbox-checked {
        background-color: $lightGray;
        img {
          opacity: 0.6;
        }
      }
    }
  }
}

.pdf-field-buttons {
  position: absolute;
  z-index: 999;
  display: none;

  &-recipient {
    color: $recipientFieldColor;
  }
  &-manager {
    color: $managerFieldColor;
  }
}

.selected-field:not(.light-red-highlight) {
  .pdf-field {
    &-recipient {
      box-shadow: 0 0 4px 2px $recipientFieldColor inset;
    }
    &-manager {
      box-shadow: 0 0 4px 2px $managerFieldColor inset;
    }
  }

  .drag-and-drop-checkbox-wrapper {
    &:has(.pdf-field-recipient) {
      box-shadow: 0 0 4px 2px $recipientFieldColor inset;
    }
    &:has(.pdf-field-manager) {
      box-shadow: 0 0 4px 2px $managerFieldColor inset;
    }
  }
}
.selected-field.light-red-highlight {
  .pdf-field-recipient,
  .pdf-field-manager {
    box-shadow: inset 0 0 3px 3px $lightRedColor;
  }
}

.pdf-field {
  &-recipient {
    height: 100%;
    border: 1px solid $recipientFieldColor;

    &:hover,
    &:active,
    &:focus,
    & input:active,
    & input:focus {
      box-shadow: 0 0 4px 2px $recipientFieldColor inset !important;
    }

    &:disabled {
      box-shadow: none !important;
    }
  }

  &-manager {
    height: 100%;
    border: 1px solid $managerFieldColor;

    &:hover,
    &:active,
    &:focus,
    & input:active,
    & input:focus {
      box-shadow: 0 0 4px 2px $managerFieldColor inset !important;
    }

    &:disabled {
      box-shadow: none !important;
    }
  }
}