@import 'src/variables';

.Title {
  display: block;
  font-family: $accentFontFamily;
  font-weight: $normalText;
  font-size: 32px;
  line-height: 1.71em;
  color: $blackColor;
}

.Logo {
  display: block;
  img {
    display: block;
    margin: 0 auto;
  }
}

.Body {
  font-size: 18px;
}