.container {
  margin: 0;
  max-width: 100%;
}

.text-sm-start {
  @media (min-width: 576px) {
    text-align: start !important;
  }
}

.display-sm-none {
  @media (min-width: 576px) {
    display: none !important;
  }
}

.display-lg-block {
  @media (min-width: 992px) {
    display: block !important;
  }
}

.display-sm-flex {
  @media (min-width: 576px) {
    display: flex !important;
  }
}

.pt-d-3 {
  @media (max-width: 576px) {
    padding-top: 1rem !important; 
  }
}

.pb-d-3 {
  @media (max-width: 576px) {
    padding-bottom: 1rem !important; 
  }
}

.p-sm-3 {
  @media (min-width: 576px) {
    padding: 1rem !important; 
  }
}

.w-d-100 {
  @media (max-width: 576px) {
    width: 100% !important; 
  }
}

.w-sm-75 {
  @media (min-width: 576px) {
    width: 75% !important; 
  } 
}

.w-md-100 {
  @media (min-width: 768px) {
    width: 100% !important; 
  }
}

.w-lg-100 {
  @media (min-width: 992px) {
    width: 100% !important; 
  }
}

.w-xl-75 {
  @media (min-width: 1200px) {
    width: 75% !important; 
  } 
}

.justify-content-md-start {
  @media (min-width: 768px) {
    justify-content: space-around;
  }
};

.justify-content-md-center {
  @media (min-width: 768px) {
    justify-content: center;
  }
};

.flex-md-direction-column {
  @media (min-width: 768px) {
    flex-direction: column;
  }
}

.border-top-lg-0 {
  @media (min-width: 992px) {
    border-top: none !important; 
  }
}