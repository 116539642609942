@import 'src/variables.scss';

.signature-pdf-field-button {
  background-color: $white;
  position: relative;
  width: 100%;
  height: 100%;

  &:disabled {
    background-color: $veryLightGray;
  }
}

.manager-signature-field-button {
  max-height: 64px;
  width: 199px;
  border: 1px solid $blackPrimary;
  border-radius: 25px;
  background-color: $white;
  position: relative;
  margin: 0;
  margin-top: 14px;
  text-align: center;

  &:disabled {
    background-color: $veryLightGray;
  }

  &:focus {
    outline: none;
  }
}

.manager-signature-field-image {
  pointer-events: none;
  max-width: 187px;
  height: 60px;

  &.img-disabled {
    background-color: $veryLightGray;
  }
}

.signature-pdf-field-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;

  &.img-disabled {
    background-color: $veryLightGray;
  }
}

.signature-pdf-field-typed {
  font-family: $typedSignatureFont;
  color: $blackColor;
  margin: 0;
}

.custom-attachment-field,
.signature-field {
  position: relative;
  border: 1px dashed $cornflowerBlue;
  background: $almostWhite;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  caret-color: transparent;

  &.is-invalid {
    padding-right: $mainFontSize;
  }

  &:active {
    background-color: $almostWhite;
  }

  &:focus {
    background: $veryLightGray;
    border-color: $brandPrimaryFocused;
    box-shadow: none;
    outline: 0;
  }

  &:hover {
    background: $veryLightGray;
  }
}

.help-text {
  font-family: $mainFont;
  font-size: 12px;
}

.custom-attachment-wrapper,
.signature-field-wrapper {
  position: relative;
  cursor: pointer;

  .signature-icon {
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: $mainFontSize;
    font-family: $mainFont;
    font-style: normal;
    font-weight: $semiBoldText;
    color: $blackColor;
    z-index: 1;
  }
  .signature-help-text {
    overflow-y: auto;
    max-height: 165px;
    color: $blackColor;
  }
}

.signature-field {
  max-height: 220px;
}

.signature-field-filled-manager {
  border: 1px solid $mediumGray;
  border-radius: 6px;
}

.signature-field-button {
  width: auto;
  max-width: 100vw;
  background: $almostWhite;
  border: 1px solid $mediumGray;
  border-radius: 0.25rem;
  display: flex;
  margin: 0 auto;
  max-height: inherit;
  justify-content: center;

  &-public {
    border: 0 none;
  }

  &:focus {
    outline: none;
  }
}

.signature-field-image {
  pointer-events: none;
  user-select: none;
  width: 100%;
  height: auto;
  object-fit: contain;
  max-width: fit-content;

  &.img-disabled {
    background-color: $veryLightGray;
  }
}

.typed-signature {
  @include text-formatting(2rem, $normalText, $typedSignatureFont, $blackColor);
  margin: 0 auto;
}