@import 'src/variables.scss';
@import 'src/scss/helpers/mixins';

.horizontal-toolbar {
  justify-content: space-between;
  flex-wrap: wrap;
  visibility: hidden;
  margin-top: -6px;
  padding: 10px;
  border-radius: 6px;
  background-color: $white;
  position: fixed;
  top: 40px;
  width: 410px;
  transition: opacity 0.75s;
  box-shadow: 0 0.25rem 0.75rem $veryLightGray;
  border: 1px solid $lightGray;
  z-index: 110;

  .popup {
    display: block;
    position: absolute;
    background-color: white;
    padding: 6px 10px;
    border: 1px solid lightgray;
    height: fit-content;
    z-index: 1;
  }

  .select-table-size {
    width: 160px;

    .table-option {
      display: flex;
      margin: 5px 2px;
      gap: 3px;
      white-space: nowrap;
    }

    .table-input {
      display: grid;
      grid-template-columns: repeat(8, auto);
      gap: 1px;
    }

    .table-unit {
      width: 15px;
      height: 15px;
      border: 1px solid lightgray;
    }
  }

  .row-title {
    width: 60px;
    @include text-formatting(1rem, $semiBoldText, $mainFont, $darkGray);
    text-align: right;
  }

  span {
    text-align: center;
  }

  svg {
    user-select: none;
  }

  .horizontal-toolbar-icon {
    fill: $blackColor;

    &:hover {
      background-color: $veryLightGray;
      border-radius: 50%;
    }
  }
}

.modal-horizontal-toolbar-editor {
  background: $white;
  z-index: 5;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
}

.create-link-modal {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  top: 0;
  left: 100%;
  border-left: 2px solid $lightGray;
  background-color: $veryLightGray;
  width: 220px;

  .submit_link_button {
    width: 40px;
    outline: none;
  }
}