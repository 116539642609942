@import 'src/variables';
@import 'src/scss/helpers/mixins';

.List {
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 6px;
  max-width: $introContentWidth;
}

.InnerWrapper {
  padding: 25px;
  @include max-screen(767) {
    padding: 25px 10px;
  }
}

.Document {
  display: flex;
  flex-direction: column;
}

.Divider {
  margin-top: 0;
}