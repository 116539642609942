@import 'src/variables';

.monday-com-board-name {
  border: 1px solid $cornflowerBlue;
  border-radius: 5px;
  background-color: $cornflowerBlue;
  font-size: $middleFontSize;

  &-error {
    background-color: $inputError;
  }
}