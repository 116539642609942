@import 'src/variables';
@import 'src/scss/helpers/mixins';

$stripeStatusColor-Draft: #505050;
$stripeStatusBackground-Draft: #dcdcdc;
$stripeStatusBorder-Draft: #b0b0b0;

$stripeStatusColor-Open: #00008b;
$stripeStatusBackground-Open: #add8e6;
$stripeStatusBorder-Open: #8aacb8;

$stripeStatusColor-Paid: #006908;
$stripeStatusBackground-Paid: #d7f7c2;
$stripeStatusBorder-Paid: #acc59b;

$stripeStatusColor-Uncollectible: #ffffff;
$stripeStatusBackground-Uncollectible: #ff6347;
$stripeStatusBorder-Uncollectible: #cc4f38;

$stripeStatusColor-Void: #ffffff;
$stripeStatusBackground-Void: #9370db;
$stripeStatusBorder-Void: #7559af;

.stripe-subscription {
  &__title {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    h2 {
      margin-bottom: 0;
      margin-right: 15px;
    }
  }
  &__tag {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    height: 20px;
    padding: 4px 8px;
    border-radius: 4px;
    text-transform: capitalize;
    font-weight: $mediumText;
    border: 1px solid transparent;
    &--trialing {
      color: $stripeStatusColor-Open;
      background: $stripeStatusBackground-Open;
      border-color: $stripeStatusBorder-Open;
    }
    &--active {
      color: $stripeStatusColor-Paid;
      background: $stripeStatusBackground-Paid;
      border-color: $stripeStatusBorder-Paid;
    }
    &--canceled {
      color: $stripeStatusColor-Draft;
      background: $stripeStatusBackground-Draft;
      border-color: $stripeStatusBorder-Draft;
    }
    &--past_due {
      color: $stripeStatusColor-Uncollectible;
      background: $stripeStatusBackground-Uncollectible;
      border-color: $stripeStatusBorder-Uncollectible;
    }
  }
}

.stripe-subscription-card {
  padding: 50px 20px 25px;
  border: 1px solid $brandPrimary;
  border-radius: 15px;
  width: calc(50% - 0.75rem);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__tag {
    position: absolute;
    left: 15px;
    top: 15px;
    background: $brandPrimaryFocused;
    padding: 2px 8px;
    border-radius: 15px;
    color: $white;
    font-size: $mainFontSize;
  }
  &__current {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $mainFontFamily;
    text-transform: capitalize;
    font-weight: $mediumText;
    font-size: $defaultFontSize;
    box-shadow: none;
    border-radius: 6px;
    min-height: 48px;
  }
  &__price {
    font-size: 22px;
    font-weight: 500;
    line-height: 1em;
    font-family: $mainFontFamily;
    margin-bottom: 0;
  }
  &__interval {
    font-size: $defaultFontSize;
    color: $darkGray;
  }
}

.stripe-action-button {
  border: none;
  background: transparent;
  font-family: $mainFont;
  font-size: 16px;
  min-width: 115px;
  transition: all .3s ease;
  &:hover, &:focus {
    color: $brandPrimary;
  }
  &:disabled {
    background: transparent;
  }
}

.stripe-invoice {
  &__grid {
    list-style-type: none;
    padding: 0;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(3, fit-content(150px)) auto;
    gap: 24px 64px;
  }
  &__link {
    text-decoration: none;
    color: $darkGray;
    display: flex;
    align-items: center;
    gap: 4px;
    transition: all .3s ease;
    &:hover, &:focus {
      color: $brandPrimary;
    }
  }
  &__link-icon {
    display: flex;
    svg {
      font-size: 16px;
    }
  }
  &__status {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    height: 20px;
    padding: 4px 8px;
    border-radius: 4px;
    text-transform: capitalize;
    font-weight: $mediumText;
    border: 1px solid transparent;
    &--draft {
      color: $stripeStatusColor-Draft;
      background: $stripeStatusBackground-Draft;
      border-color: $stripeStatusBorder-Draft;
    }
    &--open {
      color: $stripeStatusColor-Open;
      background: $stripeStatusBackground-Open;
      border-color: $stripeStatusBorder-Open;
    }
    &--paid {
      color: $stripeStatusColor-Paid;
      background: $stripeStatusBackground-Paid;
      border-color: $stripeStatusBorder-Paid;
    }
    &--uncollectible {
      color: $stripeStatusColor-Uncollectible;
      background: $stripeStatusBackground-Uncollectible;
      border-color: $stripeStatusBorder-Uncollectible;
    }
    &--void {
      color: $stripeStatusColor-Void;
      background: $stripeStatusBackground-Void;
      border-color: $stripeStatusBorder-Void;
    }
  }
}

.stripe-payment-methods {
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
  }
  &__default {
    font-size: 12px;
    background: $lightGray;
    padding: 2px 5px;
    border-radius: 4px;
    font-weight: $mediumText;
  }
  &__buttons-wrapper {
    margin-left: 40px;
    display: flex;
    justify-content: flex-end;
    flex: 0 0;
    @include max-screen(576) {
      margin-left: 20px;
    }
  }
  &__button {
    width: 26px;
    height: 26px;
    background: transparent;
    padding: 0;
    box-sizing: content-box;
    border: 2px solid transparent;
    border-radius: 4px;
    &--disabled {
      color: $mediumGray;
    }
  }
  &__menu-button {
    font-family: $mainFont;
    background: transparent;
    padding: 2px 15px;
    border: 2px solid transparent;
    font-size: 14px;
    font-weight: 400;
    color: $blackColor;
    text-align: left;
    &--red {
      color: $inputError;
    }
  }
}