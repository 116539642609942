@import 'src/variables';

.navigation-share-modal {
  border-bottom: 1px solid $veryLightGray;
  .nav-tabs {
    border: none;
  }
  .nav-tabs .nav-link {
    position: relative;
    border-bottom: none;
    &.active {
      position: relative;
      &::before {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: -1px;
        background: $white;
      }
    }
  }
}