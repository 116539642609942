@import 'src/variables';
@import 'src/scss/helpers/mixins';

.base-page-title {
  background: $white;
  box-shadow: 0 5px 10px 0 rgba($darkGray, .13);
  position: sticky;
  top: $headerHeight;
  z-index: 1000;
  transition: all .3s ease;
  .title {
    transition: all .3s ease;
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 54px;
    padding: 14px 10px;
    &--public {
      padding: 14px 178px;
      background-color: $white;
      position: relative;
      z-index: 1;
      @include max-screen(767) {
        padding: 14px 34px;
      }
    }
  }
  &__left,
  &__right {
    position: absolute;
    display: flex;
    min-width: 83px;
    left: 80px;
    flex-direction: column;
    @include max-screen(767) {
      min-width: 24px;
      left: 10px;
    }
  }
  &__right {
    left: auto;
    right: 0;
  }
  &__center {
    width: 100%;
  }
  &__button-back {
    display: flex;
    align-items: center;
    color: $blackColor;
    padding: 3px 9px 4px;
    text-decoration: none;
    border-radius: 9px;
    transition: background-color .3s ease;
    background: transparent;
    border: none;
    &:hover {
      background-color: $veryLightGray;
    }
    @include max-screen(767) {
      display: none;
    }
  }
  &__button-close {
    background: transparent;
    border: none;
    display: none;
    @include max-screen(767) {
      display: block;
    }
  }
  &__monday-subitem {
    display: block;
    @include max-screen(767) {
      display: none;
    }
  }
  &__button-text {
    font-family: $mainFont;
    font-style: normal;
    font-weight: $normalText;
    font-size: $mainFontSize;
    line-height: 145%;
    color: $blackColor;
  }
  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }
}