@import 'variables';

.light-section {
  &-outline-li {
    list-style-type: none;
    transition: all .3s ease-in;
    &.hover {
      background: rgba($brandSecondary, .1);
    }
  }
  &-outline {
    &-label {
      all: unset;
      position: relative;
      padding: 0.2em 75px 0.2em 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      span svg {
        cursor: move;
      }
    }
    &-input {
      &:disabled {
        &.form-control {
          background-color: transparent;
          border: 1px solid transparent;
        }
        cursor: move;
      }
    }
    &-text {
      &.form-control {
        background-color: transparent;
        border: 1px solid transparent;
        cursor: pointer;
      }
    }
    &-icon {
      margin-top: -4px;
    }
  }
}