@import 'src/variables';

.radio-select {
  &-recipient {
    border-color: $recipientFieldColor;

    &:checked {
      background-color: $recipientFieldColor;
      border-color: $recipientFieldColor;
    }
  }

  &-manager {
    border-color: $managerFieldColor;

    &:checked {
      background-color: $managerFieldColor;
      border-color: $managerFieldColor;
    }
  }
}

.transparent-color-text {
  display: none;
}

.some-odd-class {
  color: transparent;
}

.black-color-text {
  color: $blackColor;
}

.pdf-radio-select,
.doc-radio-select,
.form-radio-select {
  border-radius: 4px;
  gap: 10px;
  background-color: $white;

  &__label {
    font-size: inherit;
    display: inline-flex;
    align-items: center;
  }

  &__input {
    width: 20px !important;
    height: 20px !important;
    flex-shrink: 0;
    margin-top: 1px;
    cursor: pointer;

    &[type="checkbox"],
    &[type="checkbox"]:focus {
      font-size: 0.875rem;
    }
  }

  &__text {
    margin-left: 5px;
    font-weight: $normalText;
  }
}

.pdf-radio-select {
  cursor: default;
  width: 100%;
  height: 100%;
  position: relative;

  &__label {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__text {
    cursor: move;
  }
}

.drag-and-drop-element {
  &:not(.drag-and-drop-element--manager).selected-field {
    .pdf-radio-select {
      box-shadow: 0 0 4px 2px $recipientFieldColor inset;
    }
  }

  &--manager.selected-field {
    .pdf-radio-select {
      box-shadow: 0 0 4px 2px $managerFieldColor inset;
    }
  }

  .pdf-radio-select {
    &__label {
      &:hover {
        input {
          box-shadow: 0 0 4px 2px $recipientFieldColor inset;
        }
      }

      input {
        &[type="text"]:focus {
          box-shadow: 0 0 4px 2px $recipientFieldColor inset;
        }

        &:not([type="text"]):focus {
          box-shadow: 0 0 4px 2px $recipientFieldColor;
        }
      }
    }
  }

  &--manager {
    .pdf-radio-select {
      &__label {
        &:hover {
          input {
            box-shadow: 0 0 4px 2px $managerFieldColor inset;
          }
        }

        input {
          &[type="text"]:focus {
            box-shadow: 0 0 4px 2px $managerFieldColor inset;
          }

          &:not([type="text"]):focus {
            box-shadow: 0 0 4px 2px $managerFieldColor;
          }
        }
      }
    }
  }
}

.public-main {
  .drag-and-drop-element.selected-field {
    .pdf-radio-select {
      box-shadow: none;
    }
  }
}


.form-radio-select {
  display: flex;
  flex-direction: column;
  padding: 8.5px 14px;
  border: 1px solid $mediumGray;
  width: 100%;

  &.single-checkbox {
    border: none;
  }
}

.doc-radio-select {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
}