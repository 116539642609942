@import 'src/variables';

.public-header-menu {
  &::before {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba($blackColor, .3);
    z-index: 530;
    display: none
  }
  &.active {
    &::before {
      display: block;
    }
  }
  &__translate-menu {
    width: 280px;
    background: $white;
    border-radius: 4px;
    @media (max-width: 768px) {
      width: 38px;
    }
  }
  &__translate-icon {
    svg {
      fill: $white;
    }
  }
  &__menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    max-width: 300px;
    translate: 100%;
    padding: #{$headerHeight + 25px} 16px 25px;
    background: $white;
    z-index: 600;
    transition: all .3s ease;
    &.active {
      translate: 0;
    }
  }
  &__button {
    display: block;
    width: 24px;
    height: 24px;
    border: 0;
    padding: 0;
    background: transparent;
    position: relative;
    cursor: pointer;
    margin-right: 16px;
    margin-top: 5px;
    z-index: 650;
    span {
      position: absolute;
      left: auto;
      right: 0;
      top: 50%;
      translate: 0 -50%;
      display: block;
      width: 16px;
      height: 2px;
      background: $white;
      transition: all .3s ease;
      &::before, &::after {
        content: '';
        width: 24px;
        height: 2px;
        position: absolute;
        right: 0;
        background: $white;
        transition: all .3s ease;
      }
      &::before {
        translate: 0 -9px;
      }
      &::after {
        translate: 0 9px;
      }
    }
    &.active {
      span {
        background: transparent;
        &::before, &::after {
          translate: 0;
          background: $blackColor;
        }
        &::before {
          rotate: 45deg;
        }
        &::after {
          rotate: -45deg;
        }
      }
    }
  }
}