@import 'src/scss/helpers/mixins';
@import 'src/variables';

.PublicSuccessComponent {
  max-width: 370px;
  width: 100%;

  @media (max-width: 576px) {
    padding: 0 0.5rem;
  }
}

.Icon {
  padding-right: 0.5rem;
}

.Text {
  @include text-formatting(15px, $mediumText, $mainFont, $blackColor);
  margin: 0;
}

.Tip {
  @include text-formatting(15px, $normalText, $mainFont, $blackColor);
  padding: 1rem 0 1.5rem;
  margin: 0;
  text-align: center;
}

.ItemWrapper {
  border-bottom: 1px solid $mediumGray;
}

.LoginButton {
  @include text-formatting(15px, $semiBoldText, $mainFont, $white);
}

.ButtonWrapper {
  width: 65%;
}