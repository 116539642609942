@import 'src/scss/helpers/mixins';
@import 'src/variables.scss';

.field-in-focus {
  @include max-screen-and-max-height(1024, 992) {
    &.public-navbar {
      transform: translateY(-$headerHeight);
      height: 0;
      padding: 0;
      opacity: 0;
    }
    &.public-main {
      height: calc(100% - #{$progressBarHeight});
    }
    .tab-navigation {
      visibility: hidden;
      height: 0;
      min-height: 0;
      opacity: 0;
      transform: translateY(-48px);
    }
    .base-page-title {
      transform: translateY(-$headerHeight);
      .title {
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
      }
    }
    .public-editor {
      height: 100%;
      max-height: $fieldInFocusEditorMainHeight;
      .public-editor-size {
        height: calc(#{$fieldInFocusEditorMainHeight} - 40px);
        .form-view-swiper {
          height: calc(#{$fieldInFocusEditorMainHeight} - 40px);
        }
      }
    }
  }
}

.focus-icon-button {
  display: none;
  @include max-screen-and-max-height(1024, 992) {
    display: block;
    background: transparent;
    width: 20px;
    height: 20px;
    padding: 0;
    border: none;
    position: sticky;
    left: calc(100% - 40px);
    right: 20px;
    top: calc(80px + $progressBarHeight);
    z-index: 100;
    cursor: pointer;
    svg {
      display: block;
      width: 20px;
      height: 20px;
    }
  }
}