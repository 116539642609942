@import 'src/variables.scss';

.csv-data-table {
  & table {
    & td, & th {
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.csv-reader-wrapper {
  margin: 0 20px;

  & [data-html-source="true"],
  & [data-pdf-node="pdf-wrapper"] {
    border: 3px dashed $brandPrimary;
    padding: 20px 0;

    & .row {
      margin-right: 0;
    }
  }
  & .drop-zone-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $almostWhite;
    border: 1px dashed $cornflowerBlue;
    border-radius: 6px;
    padding: 40px 20px;
    margin: 20px 0;
    cursor: pointer;
  }
  & .accepted-file-csv {
    background: $white;
    display: flex;
    height: 40px;
    width: 100%;
    position: relative;
    z-index: 10;
    flex-direction: row;
    justify-content: flex-start;

    &-name {
      font-size: 15px;
      margin-left: 0.5em;
    }
    &-size {
      display: flex;
      justify-content: center;
      margin-left: 0.5em;
    }
    &-progress-bar {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0 8px;
    }
  }
}