@import 'src/variables.scss';

.property-tab-field-button {
  border-radius: 0.375rem;
  border: 1px solid $mediumGray;
  font-weight: $normalText;
  font-size: $mainFontSize;
  font-family: $mainFont;
  padding: 2px 0.5rem;
  margin: 0 3px;
  color: $blackColor;
  background-color: #ffffff;
  min-height: unset;
  min-width: auto;
  transition: all .3s ease;
  &:hover, &:focus {
    background: #FBFCFE;
  }
}

.add-signers-dropdown {
  border: 1px solid $mediumGray;
  border-radius: 5px;
  background: $white;
}