@import 'src/variables.scss';
@import 'src/scss/helpers/mixins';

* {
  box-sizing: border-box;
}

.editor-width {
  max-width: 793.7px; // A4 format
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.cancel-paddings-for-small-screen {
  @include max-screen(767) {
    padding-left: 1rem!important;
    padding-right: 1rem!important;
  }
}

.editor-main {
  height: $editorMainHeight;
  justify-content: center;
  @include max-screen(1399) {
    height: $editorMainHeightMobile;
  }
  &--send {
    overflow: auto;
    height: $editorMainSendHeightMobile;
  }
}


.field-error-message {
  color: $inputError;
  font-size: 12px;
  position: absolute;
  left: 50%;
  top: calc(100% + 2px);
  width: 100%;
  min-width: 150px;
  max-width: 200px;
  transform: translateX(-50%);
  text-align: center!important;
  background: $white;
  z-index: 100;
}