@import '../variables.scss';
@import 'node_modules/swiper/swiper.scss';
@import 'node_modules/swiper/modules/navigation/navigation.scss';
@import 'node_modules/swiper/modules/pagination/pagination.scss';
@import 'node_modules/swiper/modules/effect-fade/effect-fade.scss';

:root {
  --swiper-navigation-size: 30px;
  --swiper-navigation-color: $mediumGray;
}

.form-view-swiper {
  // 20px is default container padding
  height: calc(var(--height-only-public-editor) - 20px);
  overflow: auto;

  &.swiper-initialized {
    & .swiper-slide {
      opacity: 0;
    }
  }
}

.sections-swiper {
  height: 100%;

  .swiper-pagination {
    bottom: 10px;
  }

  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: $blackPrimary;
    opacity: 1;
    background: rgba(0, 0, 0, 0.05);
  }

  .swiper-pagination-bullet-active {
    color: $white;
    background: $brandSecondary;
  }

  .swiper-button-prev,
  .swiper-button-next {
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2 + 20px));
  }

  .list-group-item {
    display: flex;
    justify-content: center;

    label {
      margin-bottom: 0;
    }

    .row {
      width: 100%;
    }

    &-paddings {
      padding: 5px 0;
    }
  }
}

.swiper-autoheight {
  padding: 0;

  .swiper-slide {
    padding: 0 0 30px;
    min-height: 100%;
    // the max-width fixes unneeded horizontal scroll after slide recalculations (descriptions with images)
    max-width: 99.9%;
  }
}

.form-details-slider-wrapper {
  .swiper-autoheight {
    padding: 0 0 5px;
  }
}

.swiper-fraction {
  text-align: center;

  & span {
    padding: 0 15px;
  }
}

.swiper-prev-slide-button,
.swiper-next-slide-button {
  color: $blackColor;
  background: transparent;
  border: none;

  &:active,
  &:focus {
    color: $blackColor;
    outline: none;
  }

  &:disabled {
    background-color: transparent;
    color: $darkGray;
  }
}

.swiper-next-slide-button {
  transform: rotate(180deg);
}

.swiper-custom-pagination {
  display: flex;
  writing-mode: vertical-lr;
  margin-top: 20px;
}

.swiper-custom-pagination-bullet {
  height: 175px;
  max-height: 175px;
  width: fit-content !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 5px;
  margin: 5px !important;
  padding: 5px !important;
}

.swiper-pagination-bullet {
  color: $white;
}

.swiper-pagination-bullet-active {
  color: $white;
  background: $brandSecondary;
}