@import 'src/variables';

.form-title {
  font-family: $mainFont;
  text-align: center;
  font-size: 1.5rem;
  font-weight: $semiBoldText;
  color: $darkGray;
  padding: 0;
  border: 0;
  border-radius: 0;
  outline: none;
  width: 100%;
  max-width: 70%;
  margin: 0 auto;
  border-bottom: 1px solid transparent;
  transition: all .4s ease;

  &::placeholder {
    color: $mediumGray;
  }

  &:hover, &:focus {
    border: none;
    box-shadow: none;
    border-bottom: 1px solid $darkGray;
  }

  &-public {
    font-family: $mainFont;
    text-align: center;
    font-weight: $semiBoldText;
    color: $blackPrimary;
    border-bottom: 0 none;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    &:hover, &:focus {
      border-bottom: 0 none;
    }
    &__title {
      padding: 0 15px;
    }
  }
  &-simple {
    justify-content: center;
  }
}

.form-description {
  font-family: $mainFont;
  font-size: $mainFontSize;
  font-weight: $normalText;
  margin: 0 auto;
  border: 1px solid transparent;
  border-radius: 0;
  width: 100%;
  padding: 0;

  & .ql-container {
    font-family: $mainFont;
    font-size: $mainFontSize;
  }
  & .ql-editor {
    min-height: 50px;
  }

  &-public {
    padding: 0 1.5rem;

    .ql-editor {
      padding: 0;
      min-height: auto;
    }
    img {
      max-width: 100%;
    }
  }

  &:hover {
    border-bottom: 1px solid $darkGray;
  }

  &::placeholder {
    color: $mediumGray;
  }

  &-public {
    overflow-wrap: break-word;
    &:hover {
      border: 1px solid transparent;
    }
  }
}

.template-info {
  display: flex;
  flex-direction: column;
}

.description-text-area {
  min-height: 30px;
  border: 1px solid transparent;

  &:empty:after {
    content: attr(placeholder);
    opacity: 0.65;
    text-align: center;
    display: block;
  }

  &:not(.disabled):hover {
    border-bottom: 1px solid $darkGray;
  }
}

.description-save-wrapper {
  text-align: center;
}

.description-save-button {
  padding: 2px 15px;
  margin: 3px;
  border-radius: 5px;
  border-style: none;
  background-color: $brandSecondary;
}