@import 'src/variables.scss';
@import 'src/scss/helpers/mixins';

.check-list-editable {
  flex: 1;
  opacity: 1;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &.check-list-checked {
    opacity: 0.7;
    text-decoration: line-through;
  }
}

.label-text-field-editable {
  font-size: $defaultFontSize;
  font-family: $mainFont;
  font-weight: $normalText;
  color: $recipientFieldColor;
  margin-top: 0;
  overflow-wrap: break-word;
  &+[contentEditable="false"] {
    font-size: inherit;
  }

  &__after {
    &::after {
      content: ": ";
    }
  }
}




.label-color-manager {
  color: $managerFieldColor;
}

.text-field-editable,
.select-field-editable {
  height: 1.4em;
  min-width: 190px;
  outline: none;
  border: none;
  border-bottom: 1px solid $blackPrimary;
  background-color: $white;
  white-space: initial;
  border-radius: 0;
  box-shadow: none;
  line-height: normal;
  width: 100%;
  display: block;

  &:focus {
    outline: none;
    border: none;
    border-bottom: 1px solid $blackPrimary;
    border-radius: 0;
    box-shadow: none;
  }

  &.text-area-field {
    border: 1px solid $blackPrimary;
    border-radius: 0;
    min-height: 60px;
    height: auto;
    resize: vertical;
    color: $blackPrimary;

    &:disabled {
      background: $veryLightGray;
      color: $darkGray;
    }

    &:focus {
      border: 1px solid $blackPrimary;
      box-shadow: none;
    }
  }
}

.editor-input-span-field {
  max-width: 100%;
  border-bottom: 1px solid $blackColor;
  outline: none;
  padding: 0 2px;
  line-height: normal;
  white-space: normal;
}

.inline-input-span {
  min-width: auto;
  max-width: none;

  &.disable {
    background: $veryLightGray;
    color: $darkGray;
  }
}

.date-field {
  display: inline-block;
  position: relative;

  input {
    padding: 0 2px;
    display: inline-block;
    z-index: 2;
    position: relative;
    border: none;

    &:disabled {
      border-bottom: 1px solid $blackPrimary;
      padding: 0 1px;
      -webkit-text-fill-color: inherit;
    }
  }

  span {
    z-index: 2;
    position: absolute;
    right: 2px;
    bottom: 18%;
    align-items: flex-end;
  }
}

[data-slate-node=element] textarea,
[data-slate-node=element] input,
[data-slate-node=element] select {
  font-size: inherit;

  &:focus {
    font-size: inherit;
  }
}

span[data-slate-node="element"],
span[data-slate-node="element"] *,
span[data-slate-node="text"],
span[data-slate-node="text"] *,
span[data-slate-leaf="true"],
span[data-slate-leaf="true"] *,
span[data-slate-string="true"],
span[data-slate-string="true"] * {
  font-family: inherit;
  text-align: inherit;
}

span[data-slate-placeholder="true"] {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  line-height: 1;
  font-family: $mainFont;
  color: $blackPrimary;
  top: 3px;
  left: 0;
  padding-left: 5px;
  padding-top: 2px;

  ol li &,
  ul li &,
  .check-list-editable & {
    font-size: 0;
  }
}

.signature-wrapper {
  .manager-signature-field-button {
    text-align: center;
    display: block;
    height: auto;
  }

  .typed-signature {
    @include text-formatting(2rem, $normalText, $typedSignatureFont, $blackColor);
    margin: 0 auto;

    &:focus,
    :hover {
      @include text-formatting(2rem, $normalText, $typedSignatureFont, $blackColor);
    }
  }
}

/**
* Numbered list markers
* Multilevel Legal format: 1., a., i., 1., a., i., etc. Supports 9 levels, others are just decimal
* Multilevel chapter format: 1., 1.1., 1.1.1., etc.
*/
ol[data-slate-node="element"][data-list-style="decimal-legal"] {
  list-style-type: decimal;

  ol[data-list-style="decimal-legal"] {
    list-style-type: lower-alpha;

    ol[data-list-style="decimal-legal"] {
      list-style-type: lower-roman;

      ol {
        list-style-type: decimal;

        ol {
          list-style-type: lower-alpha;
        }

        ol ol {
          list-style-type: lower-roman;

          ol {
            list-style-type: decimal;

            ol {
              list-style-type: lower-alpha;
            }

            ol ol {
              list-style-type: lower-roman;

              ol {
                list-style-type: decimal;
              }
            }
          }
        }
      }
    }
  }
}

ol[data-slate-node="element"][data-list-style="decimal-chapter"] {
  padding-left: 1rem;
  list-style-type: none;
  counter-reset: list;

  li div {
    display: inline-block;

    ::after {
      clear: both;
    }
  }

  li::before {
    vertical-align: top;
    counter-increment: list;
    content: counters(list, ".") ". ";
  }
}

/* moved from styles */
.field-editable {
  display: inline-block;
  min-width: 100px;
  max-width: 240px;
}

[data-slate-editor="true"] {
  .textarea-wrap {
    position: relative;
    width: 99%;
    display: inline-block;
  }

  [data-attachments-empty="true"] {
    &::after {
      content: "Please upload attachments";
    }
  }

  .attachments-wrap {
    display: inline-block;
    position: relative;
    width: calc(100% - 2px);

    .dz-message {
      display: block;
      position: relative;
      max-width: 100%;

      span {
        line-height: 1.2em;
      }
    }
  }

  .inline-field-wrapper {
    position: relative;
    outline: none;
    display: inline;
    min-height: 24px;
    @include min-screen(576) {
      white-space: nowrap;
    }

    .signature-wrapper:nth-last-of-type(1) {
      min-width: auto;
      width: auto;
    }

    &:focus,
    &:hover,
    &:active {
      z-index: 101;
      position: relative;
    }

    &[data-element-type="signing-field"] {
      white-space: unset;
    }

    &[data-element-type="radio"],
    &[data-element-type="checkbox"] {
      display: inline-flex;
      flex-flow: row wrap;
      white-space: normal;
    }
  }

  .white-space-inline-field-wrapper {
    .label-text-field-editable {
      white-space: normal;
    }
  }

  .d-inline {
    display: inline;
  }

  // field hover and highlight effect styles
  .inline-field-wrapper,
  .attachments-wrap,
  .textarea-wrap {

    &.blue-bg,
    &.selected-field {
      &.custom-subtype-highlight:not(.light-red-highlight) {
        background-color: $brandSecondaryHalfOpacity;
      }

      &:not(.custom-subtype-highlight)::after {
        content: "";
        display: block;
        width: calc(100% + 3px);
        height: calc(100% + 10px);
        min-height: 30px;
        position: absolute;
        top: -5px;
        left: -2px;
        background-color: $brandSecondaryHalfOpacity;
        z-index: -1;
      }

      &[data-element-type="signing-field"]:has(img)::after {
        min-height: 70px;
        top: 0;
      }

      &[data-element-type="date-field"]::after {
        min-height: 30px;
        top: -15px;
        height: calc(100% + 22px);
      }

      &[data-selected-other-option="true"]::after {
        min-height: 70px;
      }

      .label-text-field-editable,
      input:not(:checked),
      select,
      .doc-radio-select,
      .manager-signature-field-button,
      .date-field,
      textarea {
        background-color: transparent;
      }

      .date-field:has(input:disabled) {
        border-width: 0;
      }
    }
  }

  .light-red-highlight {

    &.blue-bg,
    &.selected-field.custom-subtype-highlight {
      background-color: $lightRedColor;
    }

    &.selected-field:not(.custom-subtype-highlight) {
      .editor-field-view-wrapper {
        box-shadow: inset 0 0 3px 4px $lightRedColor;
      }
    }
  }
}