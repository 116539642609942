.email-autocomplete {
  .email-autocomplete-search-icon {
    position: absolute;
    right: 30px;
  }
  .email-autocomplete-search-button {
    padding: 4px;
    .MuiSvgIcon-root {
      width: 20px!important;
      height: 20px!important;
    }
  }
  .email-autocomplete-input {
    padding-right: 75px!important;
  }
}