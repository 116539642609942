/* TYPOGRAPHY */
$normalText: 400;
$mediumText: 500;
$semiBoldText: 600;
$boldText: 700;

//width
$btnWidth: 100px;

/* REDESIGN DATA */
// Fonts
$mainFontFamily: Inter;
$accentFontFamily: Bangers;

$mainFont: $mainFontFamily, Arial, sans-serif;
$accentFont: $accentFontFamily, sans-serif;
$typedSignatureFont: 'Allura', cursive, Arial, sans-serif;

$signatureHashFontSize: 10px;
$smallFontSize: 12px;
$middleFontSize: 14px;
$mainFontSize: 15px;
$defaultFontSize: 16px;
$fieldsValueFontSize: 16px;
$buttonFontSize: 16px;
$pdfFieldFontSize: 15px;
$pdfFieldValuesFontSize: 10px;

// Colors
$white: #ffffff;
$blackPrimary: #000000;
$blackColor: #2F3947;
$brandPrimary: #E7169B;
$brandPrimaryFocused: #F718A6;
$brandPrimaryShadow: #FAD3EC;
$brandTintShadow: #FED7F0;
$brandSecondary: #4C95FC;
$brandSecondaryHalfOpacity: #4c95fc46;
$cornflowerBlue: #ACCEFF;
$lightRedColor: #FFCCCB;
$green: #05D370;

// Grayscale
$darkGray: #838486;
$mediumGray: #CFD2D8;
$lightGray: #E6ECF4;
$veryLightGray: #EEF3F8;
$almostWhite: #FAFBFE;

$inputError: #d32f2f;
$recipientFieldColor: #2971D6;
$managerFieldColor: #37BE5F;
$documentFillOutStatusColor: #FFA011;
$transparent: transparent;

// Sizes
$headerHeight: 60px;
$mobileFooterHeight: 100px;
$navbarHeight: 110px;
$progressBarHeight: 4px;
$mainHeight: calc(100vh - #{$headerHeight} - var(--footer-height));
$mainHeightWithNavbar: calc(100vh - #{$headerHeight} - var(--footer-height) - #{$navbarHeight});
$maxHeightWithNavbarWithoutFooter: calc(100vh - #{$headerHeight} - #{$progressBarHeight} - #{$navbarHeight});
$editorMainHeight: calc(100vh - #{$headerHeight} - 68px);
$outlineSectionHeight: calc(100vh - #{$headerHeight} - var(--footer-height) - 75px);
$formBuilderHeight: calc(100vh - #{$navbarHeight});
$editorMainHeightMobile: calc(100vh - #{$headerHeight} - 89px);
$editorMainSendHeightMobile: calc(100vh - #{$headerHeight} - #{$mobileFooterHeight});
$editorCollectionHeight: calc(100vh - #{$headerHeight} - 125px);
$settingPageHeight: calc(100vh - #{$headerHeight});
$fieldInFocusEditorMainHeight: calc(100vh - #{$headerHeight} - 78px);

$horizontalToolbarDropdownMaxHeight: 160px;
$horizontalToolbarDropdownWidth: 185px;

$publicButtonWidth: 200px;
$modalBorderRadius: 15px;

$globalNavigationSquareSize: 260px;

// Intro
$introContentWidth: 387px;