@import '/src/variables.scss';

.public-navbar {
  background-color: $blackColor;
  height: $headerHeight;
  display: flex;
  align-content: center;
  transition: all .3s ease;
}

.unicorn-header-logo {
  pointer-events: none;
  margin-left: 36px;

  @media (max-width: 425px) {
    margin-left: 0;
  }
}

.header-text {
  font-family: $accentFont;
  font-size: 1.5em;
  font-weight: $mediumText;

  &.unicorn {
    color: $brandPrimary;
  }

  &.forms {
    color: $white;
  }
}

.mobile-menu {
  background-color: $blackColor;
  z-index: 1;
}