@import 'src/variables';
@import 'base';
@import 'helpers';
@import 'components';
@import 'root';
@import 'src/scss/helpers/mixins';

body {
  top: 0!important;
}

// to avoid swiper jumping height issue
.public-main {
  overflow: hidden;
}

// for public field help text (title)
.field-help-text {
  font-weight: $mediumText;
  font-size: $mainFontSize;
  font-family: $mainFont;

  span {
    color: $brandPrimary;
  }
}

.max-w-450 {
  width: 100%;
  max-width: 450px;
  @include max-screen(576) {
    max-width: 100%;
  }
}
.max-w-700 {
  max-width: 700px;
}
.max-w-900 {
  max-width: 900px;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
.margin-auto {
  margin: 0 auto;
}

.modal-header {
  display: block;
  border-bottom: 0 none;

  .modal-title {
    margin: 0 25px;
    text-align: center;
    font-family: $mainFont;
    font-size: 18px;
    font-weight: $semiBoldText;
    color: $blackColor;
  }

  .btn-close {
    position: absolute;
    right: 22px;
    top: 22px;
  }
}

.checkbox-help-text {
  margin-bottom: unset;
  font-weight: $normalText;
  font-size: 0.75rem;
  color: $inputError;
  letter-spacing: 0.03em;
}

.field-name {
  color: $blackColor;
  font-weight: normal;
}

.slate-list-item {
  &::marker,
  &::before {
    font-size: var(--marker-font-size);
  }
}

.public-page-editor-wrapper {
  overflow: auto;
}