@import 'src/variables.scss';
@import 'src/scss/helpers/mixins';

.toolbar-dropdown {
  background-color: $white;
  display: block;
  position: absolute;

  padding: 0.5rem 0.5rem;

  z-index: 5;
  box-shadow: 0 0 7px 0 $veryLightGray;
  border-radius: 6px;
  border: 1px solid $lightGray;

  .horizontal-toolbar-dropdown {
    width: $horizontalToolbarDropdownWidth  !important;
    max-height: $horizontalToolbarDropdownMaxHeight  !important;
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 185px;

    .without-scrollbar {
      padding: 0.25rem 0.5rem 0.25rem 0;
    }

    &_item {
      padding: 0.25rem 0.5rem;
      @include text-formatting(15px, $normalText, $mainFont, $blackColor);
      cursor: pointer;
      width: 100%;
      border-radius: 6px;
      color: $blackColor;
      text-align: left;

      .icon {
       padding-right: 8px;
       vertical-align: text-bottom;
      }

      &:hover {
        background-color: $veryLightGray;
      }
    }

    .active {
      color: $mediumGray;
    }
  }
}