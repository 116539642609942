@import 'src/variables';

.navigation-arrow-wrapper {
  display: inline-flex;
  align-items: center;

  &_icon {
    position: absolute;
    left: -40px;
    display: block;
    height: 42px;
    width: 42px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    svg {
      display: block;
      height: 42px;
      width: 42px;
    }
  }
}

.editor-chewron-nav {
  position: absolute;
  left: 25%;
  width: auto;

  .editor-chewron-nav-top {
    top: 90%;
  }
}