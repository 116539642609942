@import 'src/variables';

.document-editor {
  overflow: hidden;
  height: 100%;

  h1 {
    text-align: inherit;
  }

  &__container {
    display: flex;
  }

  &__navigation {
    width: 100%;
    max-width: 300px;
    position: relative;

    &__collapse {
      height: 200px;
      width: 40px;
    }

    ol {
      padding-left: 0;
      list-style: inside decimal;
      font-size: 14px;
      color: $darkGray;
    }

    ol li {
      cursor: move;

      &.react-autosuggest__suggestion {
        cursor: pointer;
      }
    }

    .document-editor__navigation__add-section {
      cursor: pointer;
      outline: none;
      background: transparent;
    }
  }

  &__navigation-item-active {
    font-size: 14px;
    color: $darkGray;
    z-index: 2;
  }

  &__navigation-title {
    margin-bottom: 40px;
  }

  &__section {
    /** remember about Print view styles on the Backend side */
    border: 3px dashed $veryLightGray;
    margin-bottom: 1rem;
    color: $blackPrimary;
    &:last-of-type {
      margin-bottom: 0;
    }

    &--public {
      border: 3px solid transparent;
    }

    & h1 {
      text-align: inherit;
    }
  }
}

.status-icon {
  svg {
    font-size: 10px;
  }

  .status-new {
    color: #E78587;
  }

  .status-waiting {
    color: #FFB800;
  }

  .status-draft {
    color: #D3D3D3;
  }

  .status-completed {
    color: #05D370;
  }

  .status-cancelled {
    color: #FF0000;
  }

  .status-in-progress {
    color: #FFFF77;
  }
}

.complete-document-modal-body {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 3rem;
  overflow-y: auto;
}