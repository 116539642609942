@import 'src/variables';

.digit-input-box {
  width: 36px;
  height: 50px;
  border: 1px solid $cornflowerBlue;
  border-radius: 5px;
  text-align: center;

  &:focus, &:active {
    outline: none;
    border: 2px solid $cornflowerBlue;
  }
}