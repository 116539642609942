@import 'src/variables';

.settings-page {
  height: $settingPageHeight;
}

.settings-wrapper {
  width: 100%;
  max-width: 743px;
  &--fluid {
    max-width: none;
  }
}

.settings-menu {
  background: $almostWhite;
  border: 1px solid $mediumGray;
  border-radius: 18px;
  max-width: 348px;
  &__title {
    padding: 20px 20px 0;
    margin-bottom: 23px;
    font-family: $mainFont;
    font-style: normal;
    font-weight: $semiBoldText;
    font-size: $defaultFontSize;
    color: $blackColor;
  }
  &__menu {
    margin-bottom: 20px;
    padding: 0 24px;
    a, button {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-bottom: 35px;
      position: relative;
      background: transparent;
      border: none;
      text-decoration: none;
      font-family: $mainFont;
      font-weight: $normalText;
      font-size: $mainFontSize;
      line-height: 1.74em;
      color: $blackColor;
      transition: color .4s ease;
      &::before {
        content: '';
        position: absolute;
        left: -24px;
        top: 0;
        height: 100%;
        width: 4px;
        background-color: $brandPrimary;
        display: none;
      }
      svg {
        display: block;
        width: 24px;
        height: 24px;
        margin-right: 10px;
        fill: $blackColor;
        transition: fill .4s ease;
      }
      &:hover, &:focus {
        color: $brandSecondary;
        svg {
          fill: $brandSecondary;
        }
      }
      &.is-active {
        &::before {
          display: block;
        }
      }
    }
    hr {
      display: block;
      width: 100%;
      height: 1px;
      background: $mediumGray;
      margin: 35px 0;
    }
  }
}

.account-settings {
  margin-bottom: 70px;
  &:last-of-type {
    margin-bottom: 40px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $blackColor;
    padding-bottom: 10px;
    margin-bottom: 35px;
  }
  &__title {
    color: $blackColor;
    font-family: $mainFont;
    font-weight: $mediumText;
    font-size: $defaultFontSize;
    line-height: 1.2em;
    margin: 0;
  }
  &__edit-button {
    display: block;
    border: none;
    background: transparent;
    font-family: $mainFont;
    font-weight: $normalText;
    font-size: $mainFontSize;
    line-height: 1.2em;
    color: $brandSecondary;
    padding: 0;
    border-bottom: 1px solid transparent;
    transition: all .5s ease;
    &:hover, &:focus {
      border-bottom-color: $brandSecondary;
    }
  }
  &__row {
    position: relative;
    &::before {
      content: '';
      width: calc(100% - (.5 * var(--bs-gutter-x) * 2));
      height: 1px;
      background: $mediumGray;
      position: absolute;
      left: calc(.5 * var(--bs-gutter-x));;
      bottom: 0;
    }
  }
  &__label {
    font-family: $mainFont;
    font-weight: $normalText;
    font-size: $mainFontSize;
    color: $blackColor;
    padding-right: 15px;
  }
  &__text {
    padding:  11px .75rem;
    border: 1px solid transparent;
  }
  &__input {
    border: 1px solid $mediumGray;
  }
  &__error {
    width: 100%;
    font-size: .875em;
    color: $inputError;
    margin-bottom: 20px;
  }
}