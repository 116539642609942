.cardbar {
  user-select: none;
  pointer-events: none;
  display: none;
  position: absolute;
  top: -36px;
  border-radius: 4px;
  background-color: white;
  transition: opacity 0.75s;
  box-shadow: 0 7px 5px 5px rgb(0 0 0 / 20%);
  z-index: 9;
  left: 0;
  white-space: nowrap;
}

.cardbar button {
  padding: 5px;
}

.cardbar > * {
  pointer-events: initial;
}

.cardbar.selected {
  display: block;
}
