@import 'src/variables';
@import 'src/scss/helpers/mixins';

.policies-info {
  background-color: $almostWhite;

  p,
  a {
    @include text-formatting(15px, $normalText, $mainFont, $blackColor);
  }

  a {
    text-decoration: none;
  }
}

.public-footer {
  .policies-info {
    background: transparent;
  }
}